import React from 'react'

export default ({ ...props }) => (
  <svg
    width='88'
    height='93'
    viewBox='0 0 88 93'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M55.3999 0.300027C44.7999 -0.499973 33.9999 0.300027 23.5999 2.50003C14.4999 4.40003 4.69988 8.50003 1.39988 18C0.499881 20.5 0.499881 23.2 0.399881 25.8C0.299881 28.5 0.199881 31.3 0.199881 34C-0.000119358 39.5 -0.000119359 45 -0.000119359 50.5C-0.000119359 55.6 -0.100119 60.7 0.299881 65.8C0.699881 70.8 2.09988 75.6 5.29988 79.6C8.19988 83.1 12.1999 85.6 16.2999 87.4C20.6999 89.4 25.2999 90.8 30.0999 91.5C39.7999 93.1 49.5999 92.5 59.2999 91C63.9999 90.3 68.7999 89.5 72.9999 87.3C76.7999 85.2 79.6999 81.8 81.4999 77.8C83.1999 74 84.1999 69.9 84.8999 65.8C85.8999 60.6 86.7999 55.3 87.2999 50C88.2999 39.5 87.3999 28.8 84.7999 18.7C84.1999 16.2 83.1999 13.8 81.5999 11.8C80.1999 10 78.2999 8.60003 76.2999 7.50003C71.9999 5.00003 67.1999 3.00003 62.3999 1.70003C60.0999 1.00003 57.7999 0.500027 55.3999 0.300027C53.7999 2.67923e-05 52.1999 0.600027 51.6999 2.30003C51.2999 3.70003 52.1999 5.80003 53.7999 6.00003C58.3999 6.60003 62.9999 7.80003 67.2999 9.60003C69.2999 10.4 71.1999 11.4 72.9999 12.4C74.5999 13.3 76.1999 14.4 77.2999 15.8C78.4999 17.4 78.8999 19.6 79.3999 21.5C79.9999 23.8 80.3999 26.1 80.7999 28.5C81.4999 33.2 81.7999 37.9 81.7999 42.6C81.6999 47.4 81.2999 52.1 80.4999 56.8C79.7999 61.1 79.0999 65.4 77.9999 69.6C77.0999 72.9 75.9999 76.4 73.7999 78.9C71.1999 81.9 67.5999 83.2 63.6999 84C55.1999 85.8 46.2999 86.7 37.4999 86.2C29.1999 85.7 20.5999 83.7 13.5999 78.9C10.3999 76.7 8.09988 73.8 7.09988 70.1C5.89988 65.9 6.09988 61.4 5.99988 57.1C5.89988 47.1 5.99988 36.9 6.29988 26.8C6.39988 24.5 6.29988 22.2 6.99988 19.9C7.49988 18.3 8.39988 16.8 9.59988 15.5C12.2999 12.5 15.9999 10.7 19.7999 9.50003C28.8999 6.80003 38.6999 5.90003 48.1999 6.00003C50.5999 6.00003 52.9999 6.10003 55.3999 6.30003C56.9999 6.40003 58.3999 4.80003 58.3999 3.30003C58.3999 1.60003 56.9999 0.400027 55.3999 0.300027Z'
        fill='#080808'
      />
      <path
        d='M49.3 25.4C44.4 25.2 39.2 25.1 34.5 26.8C30.4 28.3 27 30.9 24.4 34.3C19.3 41.1 19 50.5 23.4 57.8C28.3 65.8 37.8 69.3 46.8 67.4C51.2 66.5 55.2 64.3 58.5 61.3C61.8 58.3 64.7 54.2 65.4 49.8C66.3 44.1 63.7 39 60.8 34.3C58 29.8 53.9 25 48 25.5C46.4 25.6 45 26.8 45 28.5C45 30 46.4 31.6 48 31.5C51.5 31.2 53.7 34.3 55.4 37C57.3 40 59.6 43.4 59.6 47.1C59.7 53.7 52.9 59.4 47.1 61.1C40.7 63.1 33.3 61.2 29.4 55.9C25.6 50.8 25.2 44.2 28.4 38.9C30.1 36.2 32.7 33.8 35.6 32.7C39.9 31 44.7 31.2 49.3 31.4C53.2 31.5 53.2 25.5 49.3 25.4Z'
        fill='#080808'
      />
      <path
        d='M68.8 18.5001C67.5 18.1001 67.8 18.1001 66.3 18.3001C65.6 18.4001 64.6 19.2001 64.2 19.8001C63.2 21.2001 62.5 23.1001 63.4 24.5001C64.4 25.9001 66.4 25.9001 68 25.5001C68.9 25.3001 69.7 25.0001 70.3 24.3001C71.1 23.5001 71.4 22.2001 71.1 21.1001C70.7 20.0001 70.6 19.1001 69.4 18.9001'
        fill='#080808'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='87.8' height='92.4' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
