import React from 'react'

export default ({ ...props }) => (
  <svg
    width='35'
    height='308'
    viewBox='0 0 35 308'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M25.44 5.01004L14.74 5.01004L25.44 15.07L25.44 20.99L13.67 9.53L0.910018 21.9L0.910018 15.84L12.2 5.01004L0.910019 5.01004L0.910019 0.660003L25.44 0.660004L25.44 5.01004Z'
      fill='#FFF4CC'
    />
    <path
      d='M18.7 28.91L15.9001 28.91C16.8441 29.292 17.643 29.9632 18.1819 30.8274C18.7209 31.6915 18.9723 32.7041 18.9001 33.72C18.8873 34.5511 18.756 35.3761 18.5101 36.17L14.5101 35.5C14.7785 34.8076 14.9207 34.0727 14.9301 33.33C14.9301 30.84 13.3201 29.02 10.1301 29.02L0.810045 29.02L0.810045 24.74L18.61 24.74L18.7 28.91Z'
      fill='#FFF4CC'
    />
    <path
      d='M4.51004 57.9C4.50268 58.385 4.63832 58.8616 4.90004 59.27L1.78003 60.27C1.0839 59.2604 0.704423 58.0662 0.690032 56.84C0.623466 55.8088 0.941262 54.7894 1.58208 53.9787C2.22289 53.168 3.14129 52.6234 4.16003 52.45C1.92003 51.23 0.690032 48.91 0.690033 45.93C0.690033 40.64 4.83003 37.52 9.87003 37.52C14.91 37.52 19.13 40.52 19.13 45.97C19.13 48.84 17.97 50.97 16.04 51.97L18.7 51.97L18.7 56.1L6.44003 56.1C6.19162 56.0758 5.9409 56.1047 5.70451 56.1848C5.46813 56.2649 5.25149 56.3944 5.06897 56.5646C4.88644 56.7348 4.74221 56.942 4.64584 57.1722C4.54948 57.4024 4.50319 57.6505 4.51004 57.9ZM4.44003 46.74C4.44003 49.74 6.65003 51.82 9.91003 51.82C13.17 51.82 15.38 49.76 15.38 46.71C15.38 43.66 12.92 41.71 9.91003 41.71C6.68003 41.74 4.44003 43.74 4.44003 46.74Z'
      fill='#FFF4CC'
    />
    <path
      d='M25.44 67.3L11.56 67.3L18.71 74.59L18.71 80.37L10.37 71.47L0.910018 81.47L0.910018 75.37L8.66002 67.28L0.910019 67.28L0.910019 63.04L25.44 63.04L25.44 67.3Z'
      fill='#FFF4CC'
    />
    <path
      d='M25.44 88.19L11.56 88.19L18.71 95.48L18.71 101.26L10.37 92.36L0.910018 102.36L0.910018 96.27L8.66002 88.17L0.910019 88.17L0.910019 83.93L25.44 83.93L25.44 88.19Z'
      fill='#FFF4CC'
    />
    <path
      d='M4.51004 123.49C4.50287 123.972 4.63858 124.445 4.90004 124.85L1.78003 125.85C1.08224 124.841 0.702598 123.647 0.690032 122.42C0.623763 121.39 0.941894 120.372 1.58287 119.563C2.22385 118.754 3.14211 118.211 4.16003 118.04C3.03134 117.385 2.10829 116.427 1.49513 115.275C0.881971 114.123 0.603113 112.822 0.690033 111.52C0.690033 106.23 4.83003 103.11 9.87003 103.11C14.91 103.11 19.13 106.11 19.13 111.55C19.13 114.43 17.97 116.55 16.04 117.55L18.7 117.55L18.7 121.69L6.44003 121.69C6.19129 121.664 5.93989 121.692 5.70277 121.771C5.46566 121.851 5.24833 121.98 5.06544 122.151C4.88255 122.321 4.73834 122.529 4.64253 122.76C4.54672 122.991 4.50155 123.24 4.51004 123.49ZM4.44003 112.31C4.44003 115.31 6.65003 117.39 9.91003 117.39C13.17 117.39 15.38 115.32 15.38 112.27C15.38 109.22 12.92 107.33 9.91003 107.33C6.68003 107.33 4.44003 109.33 4.44003 112.31Z'
      fill='#FFF4CC'
    />
    <path
      d='M18.7 132.74L15.9001 132.74C16.8452 133.118 17.6455 133.786 18.1849 134.65C18.7243 135.513 18.9748 136.525 18.9001 137.54C18.8873 138.371 18.756 139.196 18.5101 139.99L14.5101 139.33C14.7776 138.634 14.9198 137.896 14.9301 137.15C14.9301 134.67 13.32 132.84 10.13 132.84L0.810039 132.84L0.81004 128.64L18.61 128.64L18.7 132.74Z'
      fill='#FFF4CC'
    />
    <path
      d='M4.51002 161.74C4.50286 162.222 4.63856 162.695 4.90002 163.1L1.78001 164.1C1.08222 163.091 0.702582 161.897 0.690017 160.67C0.623748 159.64 0.941879 158.622 1.58285 157.813C2.22383 157.004 3.1421 156.461 4.16002 156.29C3.03132 155.635 2.10828 154.677 1.49512 153.525C0.881956 152.373 0.603097 151.072 0.690017 149.77C0.690018 144.48 4.83001 141.36 9.87001 141.36C14.91 141.36 19.13 144.36 19.13 149.8C19.13 152.68 17.97 154.8 16.04 155.8L18.7 155.8L18.7 159.94L6.44002 159.94C6.19127 159.914 5.93987 159.942 5.70276 160.021C5.46564 160.101 5.24832 160.23 5.06543 160.401C4.88254 160.571 4.73832 160.779 4.64252 161.01C4.54671 161.241 4.50154 161.49 4.51002 161.74ZM4.44002 150.55C4.44002 153.55 6.65002 155.64 9.91002 155.64C13.17 155.64 15.38 153.57 15.38 150.52C15.38 147.47 12.92 145.58 9.91002 145.58C6.68002 145.61 4.44002 147.61 4.44002 150.58L4.44002 150.55ZM28.44 157.25L24.76 158.3L21.99 148.56L25.29 147.51L28.44 157.25Z'
      fill='#FFF4CC'
    />
    <path
      d='M27.44 177.74L24.44 176.47C22.2 181.56 17.44 184.15 11.86 184.15C4.95 184.15 0.680007 180.15 0.680008 174.58C0.680008 169.78 3.87 165.89 9.13 165.89C10.1307 165.848 11.1292 166.013 12.0634 166.374C12.9977 166.735 13.8476 167.284 14.5605 167.988C15.2734 168.691 15.8338 169.534 16.2071 170.463C16.5803 171.393 16.7583 172.389 16.73 173.39C16.73 176.12 15.4 179.03 12.84 180.19C14.5441 180.209 16.2164 179.728 17.6493 178.805C19.0822 177.883 20.2125 176.559 20.9 175L18.27 173.92L18.94 172.06L21.57 173.11C21.8725 171.883 22.0236 170.624 22.02 169.36L25.77 169.53C25.7934 171.22 25.5711 172.904 25.11 174.53L28.05 175.72L27.44 177.74ZM4.66 174.62C4.63194 175.201 4.72224 175.783 4.92541 176.328C5.12859 176.874 5.44042 177.372 5.84201 177.794C6.2436 178.215 6.72659 178.551 7.26169 178.78C7.79679 179.009 8.37286 179.127 8.955 179.127C9.53714 179.127 10.1132 179.009 10.6483 178.78C11.1834 178.551 11.6664 178.215 12.068 177.794C12.4696 177.372 12.7814 176.874 12.9846 176.328C13.1878 175.783 13.2781 175.201 13.25 174.62C13.2781 174.039 13.1878 173.457 12.9846 172.912C12.7814 172.366 12.4696 171.868 12.068 171.446C11.6664 171.025 11.1834 170.689 10.6483 170.46C10.1132 170.231 9.53714 170.113 8.955 170.113C8.37286 170.113 7.79679 170.231 7.26169 170.46C6.72659 170.689 6.2436 171.025 5.84201 171.446C5.44042 171.868 5.12859 172.366 4.92541 172.912C4.72224 173.457 4.63194 174.039 4.66 174.62Z'
      fill='#FFF4CC'
    />
    <path
      d='M14.64 213.89C13.594 213.91 12.5549 213.716 11.5872 213.318C10.6195 212.92 9.74379 212.328 9.01437 211.578C8.28495 210.828 7.71736 209.936 7.34677 208.958C6.97619 207.979 6.8105 206.935 6.86 205.89L6.86 201.62L0.86 201.62L0.860001 197.24L25.99 197.24L25.99 201.59L21.93 201.59L21.93 206.32C21.93 211.47 18.35 213.89 14.64 213.89ZM10.82 205.69C10.7783 206.194 10.8445 206.702 11.0142 207.178C11.184 207.655 11.4533 208.09 11.8044 208.454C12.1554 208.818 12.5801 209.104 13.0501 209.291C13.5201 209.478 14.0246 209.563 14.53 209.54C14.9995 209.558 15.4674 209.475 15.9022 209.297C16.337 209.119 16.7287 208.85 17.0508 208.508C17.373 208.166 17.6181 207.759 17.7698 207.314C17.9214 206.87 17.976 206.398 17.93 205.93L17.93 201.59L10.82 201.59L10.82 205.69Z'
      fill='#FFF4CC'
    />
    <path
      d='M11.34 229.03L0.900024 229.03L0.900024 224.74L11.34 224.74L25.44 215.5L25.44 220.74L15.28 226.84L25.44 232.92L25.44 238.18L11.34 229.03Z'
      fill='#FFF4CC'
    />
    <path
      d='M25.44 245.54L14.74 245.54L25.44 255.6L25.44 261.53L13.67 250.07L0.910018 262.44L0.910018 256.37L12.19 245.54L0.900024 245.54L0.900024 241.2L25.44 241.2L25.44 245.54Z'
      fill='#FFF4CC'
    />
    <path
      d='M0.340011 271.17C0.340011 267.17 2.62002 263.88 6.82002 263.56L7.56001 267.77C7.13933 267.756 6.72045 267.831 6.33061 267.99C5.94077 268.148 5.58869 268.387 5.29726 268.691C5.00582 268.995 4.78156 269.356 4.63904 269.753C4.49652 270.149 4.43892 270.57 4.47002 270.99C4.47002 272.78 5.56002 274.11 7.80002 274.11L25.44 274.11L25.44 278.46L8.20001 278.46C2.76001 278.46 0.340011 275.24 0.340011 271.17Z'
      fill='#FFF4CC'
    />
    <path
      d='M13.17 307.55C5.84 307.55 0.339995 302.22 0.339996 295.04C0.339996 287.86 5.84 282.53 13.17 282.53C20.5 282.53 25.99 287.85 25.99 295.04C25.99 302.23 20.44 307.55 13.17 307.55ZM4.44 295.04C4.44 299.77 8.12 303.14 13.14 303.14C18.16 303.14 21.83 299.77 21.83 295.04C21.83 290.31 18.15 286.94 13.14 286.94C8.13 286.94 4.44 290.34 4.44 295.04ZM34.55 301.38L30.87 302.44L28.1 292.69L31.4 291.69L34.55 301.38Z'
      fill='#FFF4CC'
    />
  </svg>
)
