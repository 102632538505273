import React from 'react'

export default ({ ...props }) => (
  <svg id='Layer_1' x='0px' y='0px' viewBox='0 0 1845.3 1454.3' {...props}>
    <g>
      <g>
        <path
          d='M1734.5,744.1c6.7-0.3,11.6,3.2,16,6.8c9.4,7.7,18.5,15.7,27.5,23.8c11.1,10.1,18.9,22.6,24.2,36.6
			c4.3,11.2,8.5,22.6,11.3,34.3c4.7,19.5,7.6,39.5,4.8,59.7c-4.3,31.4-15,60-38.2,82.9c-13.8,13.7-30.5,21.3-49.4,26.1
			c-0.1,1-0.2,2.5-0.3,3.9c-0.9,13.7-2,27.5-2.8,41.2c-0.4,6.8,0.3,13.7-0.4,20.5c-0.6,6.5-1.7,13.2-3.7,19.4c-1,3.1-4.1,6.3-7,7.9
			c-12.8,7-25.8,13.7-38.9,20.1c-27.6,13.4-56.1,24.7-84.8,35.5c-24.8,9.4-49.3,19.5-73.9,29.2c-23.1,9.1-46.4,17.9-69.4,27.3
			c-23.8,9.7-47.5,19.9-71.2,30c-34,14.4-67.9,29.3-102.2,43.2c-22.8,9.3-46.3,17.1-69.3,25.8c-24.7,9.4-49.3,18.9-73.9,28.6
			c-27.4,10.8-54.6,22.1-82.1,32.6c-30.7,11.7-61.6,22.9-92.6,33.8c-14.9,5.3-30.7,7.8-46.4,8.3c-11,0.4-22.2-1.7-32.6-5.6
			c-27.2-10.3-54.5-20.5-81.5-31.3c-33.5-13.4-66.7-27.5-100.1-41.1c-30.8-12.5-61.7-24.7-92.7-36.8c-25.4-9.9-51.1-19.1-76.6-29.1
			c-22.5-8.8-44.8-18.2-67.2-27.4c-16.2-6.7-32.4-13.7-48.6-20.4c-13-5.4-26-10.6-39-16.1c-7-3-13.8-6.7-20.8-9.6
			c-14.1-6-28.3-11.5-42.4-17.5c-28.3-12.1-56.5-24.3-84.7-36.8c-35-15.5-69.8-31.2-104.7-46.9c-3.8-1.7-7.5-3.6-11-5.8
			c-4.5-2.7-7.4-6.6-7.4-12.2c0.1-11,0.1-21.9,0.1-32.9c0.1-17.6,0.1-35.3,0.2-52.9c0-2.5-0.6-4.3-2.9-5.8
			c-6.7-4.3-13.1-9-19.8-13.3c-11.3-7.2-21.2-15.9-30-26c-9.1-10.4-14.6-22.2-18.5-35.3c-4.7-15.9-6.3-31.9-5.6-48.2
			c0.8-19.7,2.9-39.2,11-57.4c4.4-10,10-19.5,15.5-28.9c3.6-6.2,8.1-11.8,12-17.8c5.3-8.3,12.8-14.4,20.2-20.6
			c5.7-4.8,11.5-9.5,17.5-14.1c4.6-3.5,6.7-7.9,6.8-13.6c0.5-27,1.2-53.9,1.7-80.9c0.2-11.2-0.3-22.3-0.2-33.5
			c0.6-37,1.4-73.9,1.9-110.9c0.4-30.1,0.4-60.3,0.8-90.4c0.3-27,0.7-54,1.4-80.9c0.7-26.3,1.8-52.6,2.8-78.9
			c0.4-11.8,1.2-23.6,1.4-35.4c0.2-11.6-0.5-23.3-0.3-34.9c0.4-25.8,1.1-51.6,1.8-77.4c0.2-6.5,1-12.9,1.8-19.3
			c1.1-8.8,10.1-17.5,18.9-18.4c1.3-0.1,2.9,0.5,4.1,1.2c9.3,5.4,18.5,11.1,27.9,16.3c17.1,9.5,34.4,18.7,51.6,28.1
			c30.8,17,61.4,34.3,92.4,51c28.2,15.2,56.6,30.1,85.2,44.6c22.5,11.4,45.4,22,68.2,32.8c33.9,16.1,67.8,32.1,101.7,48
			c9,4.2,18.3,7.7,26.8,11.1c8.3-9.1,16-18,24.1-26.5c16.2-17.1,35.2-30.7,54.2-44.4c27.8-19.9,58.2-35,89.5-48.3
			c3.6-1.5,7.4-2.5,11.1-3.8c-0.1-0.4-0.2-0.7-0.3-1.1c-7.6,2-15.4,3.7-22.9,6.1c-7.7,2.5-15.1,5.8-22.7,8.8
			c-2.1,0.8-4.2,1.4-6.3,2.1c-0.2-0.3-0.4-0.7-0.6-1c0.5-0.9,0.9-1.9,1.7-2.5c18.7-16.3,39.9-27.4,64.6-30.7
			c9.5-1.3,19.2-1.3,28.9-1.4c14.6-0.2,29.2-0.1,44.2-0.8c-9.9-8.7-19.9-16.8-26.2-28.2c0.3-0.4,0.6-0.9,0.9-1.3
			c1.4,0.5,2.9,0.8,4.3,1.4c16.4,6.8,32.5,14.2,49.2,20.3c9.4,3.4,19.9,3.9,29.5,6.9c7.6,2.3,13.6,1.3,20.4-2.9
			c18.3-11.5,38.3-19.8,58.8-26.7c4.5-1.5,4.5-1.5,7.9,0.3c-10,12.3-22.6,21.5-35.1,31.9c1.7,0.2,2.9,0.4,4.1,0.3
			c9.7-0.2,19.3-0.8,29-0.7c21.9,0.2,43.9,0.5,65.4,5.7c12,2.9,23.2,7.8,33.6,14.5c1.4,0.9,2.5,2.2,3.4,4.2
			c-27.2-7.9-54.8-8.7-82.8-7.9c0.9,0.4,1.7,1,2.6,1.2c51.4,13.9,98.3,36.4,138.7,71.6c5.5,4.8,10.7,10.1,15.4,15.7
			c11.1,13.2,21.7,26.8,33,40.8c1.2-0.5,3.2-1.3,5.1-2c27.4-11.1,53.5-25,79.6-38.8c14.4-7.6,29.3-14.2,43.9-21.2
			c10.3-5,20.6-10.1,31-14.9c14.6-6.8,29.4-13,43.9-20c29.3-14.2,58.5-28.5,87.6-43.1c24-12,47.8-24.6,71.8-36.7
			c16.1-8.2,32.5-15.9,48.7-23.9c14.7-7.3,29.2-15.2,44-22.2c9-4.3,19-6.2,27.8,0.1c6.9,4.9,12.6,11.5,13.2,20.8
			c0.7,10.6,1.3,21.3,2,31.9c1.1,14.6,2.4,29.1,3.4,43.7c0.7,10.1,1,20.3,1.6,30.4c1.2,18.8,2.6,37.5,3.8,56.3
			c0.9,14.5,1.8,28.9,2,43.4c0.6,45.6,1,91.3,1.1,136.9c0.1,18.5-0.9,37-0.8,55.4c0.1,18.8,1,37.6,1.4,56.4
			c0.3,15.6,0.2,31.3,0.7,47c0.6,17.3,1.7,34.6,2.6,51.8c0.1,1.2,0.2,2.3,0.2,3.5c0.4,24.5,0.6,48.9,1.1,73.4
			C1732.7,729,1733.8,736.4,1734.5,744.1z M925,471.4c0.9-8.9,1.4-17.3,0-25.7c-1-6.2-9.6-13.9-15.7-15.2c-4-0.8-8-2-11.8-3.7
			c-19.3-8.8-38.2-19-58-26.4c-26.5-9.9-52.2-21.4-78.1-32.5c-17.4-7.4-33.9-16.7-51.9-22.9c-16-5.5-31.5-12.3-47.2-18.6
			c-4.5-1.8-8.7-4.1-13.2-5.9c-23.6-9.1-47.7-17.4-70.9-27.4c-25-10.7-49.4-22.8-73.9-34.7c-40.1-19.6-80-39.5-120-59.3
			c-19.6-9.7-39.6-18.7-58.8-29.3c-33.4-18.5-66.5-37.5-100.9-54.2c-26.9-13.1-52.4-28.8-77.6-44.7c-6.9-4.4-12-2.6-12.8,5.7
			c-0.6,6.8-1.8,13.5-2.2,20.3c-0.7,13.4-1.2,26.9-1.5,40.3c-0.2,6.6,0.3,13.3,0.4,20c0.2,9.8,0.2,19.6,0.4,29.5
			c0.1,5.8,0.8,11.6,0.6,17.4c-0.7,13.6-1.8,27.2-2.6,40.8c-0.5,9-0.7,18-1.2,26.9c-0.9,15.6-2.1,31.2-2.7,46.8
			c-0.4,12.5,0,25-0.2,37.5c-0.4,23.3-0.8,46.6-1.4,69.9c-0.8,35-1.9,69.9-2.5,104.9c-0.5,25.6-0.3,51.3-0.6,76.9
			c-0.3,27.6-1.2,55.3-1.3,82.9c0,14.1,0.6,28.3,2,42.4c1,10.4,3.5,21,11.5,28.5c7,6.5,14.9,12,22.5,17.9c0.7,0.5,1.7,0.6,2.9,0.9
			c-0.2-3.9-0.1-7.4-0.6-10.8c-2.6-15.5-0.9-31.2-1.6-46.8c0-0.5,0.1-1,0.1-1.5c0.4-8.5,1.3-17,1-25.4c-0.7-19.3-1.4-38.6-0.6-57.9
			c0.8-18.3,0.2-36.6,0.1-55c0-9.5-0.5-19-0.3-28.5c0.3-16.1,0.8-32.3,1.5-48.4c0.4-9.5,1.1-18.9,2.2-28.3c0.5-4,2-7.7,5.2-10.9
			c5.6-5.6,12.6-7.1,19.9-8.1c13.7-2,24.9,3,32.2,15.3c9.2,15.5,11.5,32.7,9,50.6c-0.6,4-1,8-1.6,12.8c10.3-4.5,20.4-4.8,30.6-4
			c13.1,0.9,26.2,2.2,38.2,8.4c7.2,3.7,14.4,7.6,21.7,11.1c7.6,3.6,13.8,8.9,18.5,15.6c11.9,16.7,20.7,34.8,24.1,55.2
			c1.1,6.7,2.5,13.4,2.6,20.2c0.1,10.1-2.6,20-4.5,29.9c-4.8,25-18.2,44.7-36.3,61.7c-10.9,10.3-24.4,16-38.4,20.4
			c-12.8,4-25.9,6-39.3,3.7c-5.3-0.9-10.5-2.4-15.4-3.6c-1.2,11-1.8,22.2-3.7,33.1c-2.1,12.1-4.9,24.1-8.1,35.9
			c-2,7.1-7.8,10.4-15.2,10.5c-1.8,0-3.7-0.2-5.5,0c-1,0.1-2.4,0.4-3,1.1c-4.3,5.2-8.5,10.5-13,16c3.6,3.4,6.7,7.1,12.3,8.7
			c12.3,3.5,24.3,7.9,36.2,12.4c10.2,3.8,20.3,8.1,30.4,12.3c4.8,2,9.3,4.3,14,6.5c13.2,5.9,26.4,11.6,39.5,17.8
			c5,2.4,10.4,4.9,14.3,8.7c7.3,7.2,7.4,17.2,6.9,26.6c-0.8,16.4-2.6,32.8-3.6,49.3c-1.9,29.7-3.4,59.5-5.3,89.2
			c-0.4,6.8-1.2,13.6-2.7,20.2c-1.3,5.9-3.7,7-9.5,5.6c-4.3-1-8.7-2-13-3.2c-13.1-3.7-26.1-7.4-39.1-11.4
			c-8.8-2.8-17.6-5.9-26.2-9.3c-19-7.4-37.9-15.1-56.8-22.7c-2.1-0.8-4.1-2.1-5.9-3.4c-4.6-3.4-5.7-8.4-5.7-13.7
			c0-6.5,0.7-12.9,0.7-19.4c0-11.6-0.4-23.3-0.6-34.9c0-1.2,0-2.5,0-3.9c-0.8,0.3-1.1,0.3-1.3,0.5c-10.4,8.7-22,13.2-35.9,10.1
			c-5.4-1.2-8.5,1.4-9.2,6.9c-0.7,5.3-0.7,10.6-0.5,15.9c0.5,17.4,1,34.9,2.1,52.3c0.3,4.7,1.2,10.1,6.2,12.8
			c4.7,2.5,9.5,4.7,14.3,7c21.8,10.5,43.6,21.2,65.5,31.6c16.2,7.7,32.4,15.4,48.9,22.4c22.6,9.6,45.5,18.6,68.2,27.8
			c10,4.1,20,8,29.9,12.3c9.4,4,18.6,8.6,28.2,12.4c12.6,5.1,25.6,9.2,38.2,14.5c36.5,15.2,72.8,31.2,109.5,46.2
			c31.4,12.9,62.8,26,94.9,37c38,13.1,75.2,28,111.9,44.4c17.4,7.8,34.7,16,53.1,21.3c30.3,8.8,59.6,20.4,88.8,32.3
			c8.4,3.4,17.1,5.4,26.3,4.7c4.7-0.3,8-2.6,8.3-6.3c0.4-5,1-10,0.9-14.9c-0.4-12.1-1.2-24.2-1.7-36.3c-0.2-5.8-0.1-11.7,0.1-17.5
			c0.2-7.1,0.7-14.2,1.1-20.9c-8.9,0-15.3-4.5-21.9-8.6c7.7-0.3,14.7,1.9,22.2,5.6c0.8-29.3,1.6-57.8,2.4-86.1
			c-6.2-2.2-12-3.4-17-6.6c0.1-0.5,0.2-1,0.4-1.5c5.3,0.3,10.7,0.6,16.3,1c0-16.9,0-33.5,0-50.1c-6-1.5-11.3-3.9-16-7.4
			c0.2-0.6,0.4-1.2,0.6-1.8c5.4,1,10.7,1.9,16.4,3c0-24.1,0-48.1,0-72c-7-2-14.3-3.1-20.4-7.3c0.1-0.4,0.2-0.8,0.3-1.2
			c6.7,1.3,13.4,2.5,19.9,3.7c0-39.7,0-79,0-118.2c-7.6-0.5-13.2-4.3-19-7.9c6.4-1.2,12.3,0.9,18.6,2.9c0-2.1,0-3.9,0-5.7
			c0.1-6.3,0.1-12.7,0.2-19c0.6-25.1,1.2-50.2,1.8-75.4c0.1-2.4-0.7-3.5-3.1-4.3c-5.5-1.8-10.8-4-16.2-6c-0.9-0.3-1.8-0.7-2.7-1.1
			c0.4-0.6,0.8-1.2,1.2-1.8c7.1-0.1,13.7,2.6,20.8,4.4c3.2-40.1,4.4-79.9,3.6-119.8c-5.8-2.1-11.4-4.1-16.9-6.1
			c0.1-0.6,0.3-1.2,0.4-1.8c5.5,0.7,10.9,1.4,16.8,2.1c0-3,0-5.4,0-7.7c0-12.8,0-25.7-0.1-38.5c-0.3-22.8-0.7-45.6-1-68.4
			c0-0.3-0.2-0.6-0.3-0.7c-5.3-2.2-10.5-4.3-15.6-6.4c5.9-2.7,11,0.2,16.4,1.4c0.2-0.6,0.5-1,0.5-1.5c0.4-11.5,0.6-22.9,1.1-34.4
			c0.4-8.8,1.6-17.5,1.9-26.3c0.3-9.3-0.1-18.6,0.1-27.9c0.2-9.6,0.7-19.2,1.2-29.1c-7.1-3-14.5-4.9-21-8.6c0.2-0.5,0.3-1,0.5-1.5
			C911.1,469,917.9,470.2,925,471.4z M1710.6,1017.3c-9.3-2.8-17.3-5.2-25.4-7.5c-11.2-3.2-19.6-9.4-25.7-19.6
			c-7.1-12-7-23.2,2.2-33.9c0.7-0.8,1.5-1.4,2-2.2c5.6-8.6,4.8-8.2-1.1-14.1c-0.8-0.8-1.8-1.5-2.6-2.3c-10.2-9.8-13.7-21.7-11-35.5
			c2-10.3,6.5-19.5,14-27c3-2.9,3-6,1.4-9.5c-1.5-3.1-3.4-6.1-4.6-9.4c-3.2-8.3-6.1-16.6-4.2-26c4.5-22.5,19.2-36.5,38-47.3
			c6.7-3.8,13.1-7.7,18.4-13.4c4.4-4.7,6.8-9.1,6.6-16.1c-0.9-28.1-0.9-56.3-1.1-84.4c-0.2-21.2,0.2-42.3-0.4-63.5
			c-0.9-34.3-2.6-68.5-3.5-102.8c-1-34.8-1.5-69.6-2.2-104.3c-0.1-6,0-12-0.2-18c-1.2-30.1-2.6-60.2-3.8-90.3
			c-0.6-14.6-0.6-29.3-1.3-43.9c-1.4-29.8-3-59.5-4.5-89.3c-0.9-17.9-1.9-35.9-2.6-53.8c-0.2-4.5-1.2-8.7-3.9-12
			c-4.7-5.8-9.2-9.9-17.4-6.5c-3.5,1.5-7.2,2.7-10.5,4.4c-24.2,12.5-48.3,25.1-72.4,37.8c-27.5,14.4-54.9,29.2-83.5,41.5
			c-8.2,3.5-16.3,7.3-24.4,11.2c-5.5,2.7-10.8,6-16.3,8.5c-39.7,18.1-78.7,37.9-117.2,58.4c-13.2,7-26.9,13-40.4,19.2
			c-6.4,3-13.1,5.6-19.6,8.3c-3.5,1.5-7.2,2.5-10.6,4.4c-9.3,5.1-18.3,10.8-27.7,15.6c-13.1,6.5-26.4,12.6-39.7,18.5
			c-15.7,7-31.7,13.5-47.5,20.3c-9.6,4.1-19.1,8.4-28.6,12.8c-8.6,4-17,8.3-25.5,12.4c-8.7,4.2-17.4,8.3-26.2,12.2
			c-11.1,5-22.4,9.4-33.3,14.6c-12.9,6-25.6,12.6-38.5,18.7c-22.9,11-45.9,21.9-68.8,33c-5,2.4-8.4,5.8-8.2,12.3
			c0.3,11.3-0.2,22.6-0.4,34c0,1.8,0.2,3.5,0.4,5.3c5-1,8.9-4.8,14-4.3c-3.2,2.8-6.4,5.6-10,7.3c-3.6,1.8-4.5,4.1-4.5,7.9
			c0.1,24.6,0.1,49.3,0.1,73.9c0,1.4,0.3,2.8,0.6,4.6c5.2-1.7,9.8-3.3,14.4-4.9c0.2,0.5,0.4,1,0.6,1.4c-3.2,2.1-6.2,4.8-9.8,6.1
			c-5.1,2-6.2,5.4-6.3,10.4c-0.1,10-1,19.9-1.2,29.9c-0.2,10.5-0.1,20.9-0.1,31.6c3.8-0.7,7.5-1.3,11.2-2c0.2,0.5,0.4,1,0.5,1.5
			c-2.5,1.6-5.2,3.1-7.5,5c-2.1,1.7-5.1,2.7-5.4,6c-0.5,5.3-1.4,10.6-1.5,15.9c-0.4,10.8-0.4,21.6-0.6,32.5c-0.1,7.2,0,14.5,0,22.1
			c5.5-0.9,9.7-4.1,14.7-3c-3.7,2.5-7.5,4.5-11.4,6.4c-2.1,1-3.2,2.4-3.4,4.9c-1.3,24.9-2.7,49.9-4,74.8c-0.1,1.9,0,3.8,0,6
			c6.5-0.8,11.8-4.4,17.9-3.2c-4.3,3-8.7,5.6-13.3,7.6c-3.5,1.5-4.9,3.4-4.9,7.5c0.2,23.5,0,47,0.1,70.4c0,1.7,0.3,3.4,0.5,5.1
			c4.8-0.2,8.6-3.5,13.1-1.6c-2.9,2.3-5.8,4.7-9.2,5.8c-3.8,1.3-4.7,3.5-4.7,7c-0.1,22.5-0.3,45-0.4,67.4c0,1.1,0.3,2.1,0.5,3.2
			c4.9-0.1,8.9-3.5,13.8-1.9c-3.5,2.7-7,5-10.7,7c-2.5,1.3-3.6,3-3.5,5.9c0,8.5-0.2,17-0.4,25.5c-0.3,13.1-0.9,26.3-1.2,39.4
			c-0.2,8.8,0,17.6,0,26.6c5.1-0.2,9-4.1,15.1-2.7c-4.5,3.4-8,6.3-11.8,8.8c-2.4,1.5-3.3,3.2-3.4,6c-0.1,17-0.5,34-0.7,50.9
			c-0.1,7.3,0,14.6,0,22.3c5-1.3,9.6-2.4,14.1-3.6c0.2,0.6,0.4,1.1,0.6,1.7c-4.9,2.9-9.8,5.7-15.2,8.9c0,24.3,0,48.9,0,73.6
			c4.5-0.3,8.4-3.7,13-3c0.2,0.5,0.4,0.9,0.5,1.4c-4.6,3.1-9.2,6.1-14.6,9.7c-0.6,22.1-1.3,45-2,68.4c6.6-1.4,12.2-2.5,17.8-3.7
			c0.1,0.4,0.2,0.8,0.3,1.3c-3.9,3.9-8.9,6.2-14,7.8c-3.2,1-3.8,2.5-3.6,5.3c0.8,10.9,1.3,21.9,2.3,32.8c0.5,5.1,1.6,10.2,3.2,15.1
			c1.9,5.8,6,7.6,11.7,5.5c9.3-3.4,18.5-7.2,27.9-10.6c40.7-15,81.4-30,122.1-45c24.4-9,48.9-17.6,73.1-27
			c21-8.2,41.6-17.6,62.6-25.8c36.8-14.2,73.8-27.7,110.7-41.9c54.2-20.8,107.7-43.4,161-66.3c30.2-13,61-24.6,91.4-37
			c8.6-3.5,17-7.5,25.4-11.5c9.4-4.5,18.4-9.9,28-14.1c20.8-9,41.7-17.5,62.7-26c3.3-1.3,4.7-2.9,4.4-6.6c-0.4-4.5-0.2-9,0-13.5
			c0.3-8.1,1-16.2,1.1-24.3C1710.8,1039.3,1710.6,1027.9,1710.6,1017.3z M1771.5,905.2c3.8,4.1,4,5.8,0.4,9.2
			c-4.8,4.6-9.8,9-14.8,13.4c-12.7,11-27.1,18.3-43.9,20.8c-10.1,1.5-20.3,3.2-30.3,5.5c-13,3-17.6,9.3-15.3,22
			c1.8,9.9,6.7,18.5,16.7,22.7c16.6,7,33.8,6.4,51,3.5c13.8-2.3,24.8-10.5,34.7-19.7c15.5-14.3,26.7-31.8,32.8-52.1
			c6.4-21.3,10.5-42.8,6.6-65.2c-3.7-21-10.9-40.7-21.1-59.4c-9.1-16.7-23.5-28.7-38.7-39.7c-2.9-2.1-7.5-2-11.4-2.5
			c-2.1-0.3-3.3,1.1-4.5,3.1c-4.2,7.4-9,14.3-16.1,19.3c-10.3,7.3-20.8,14.3-30.9,21.9c-12.1,9.1-19,21.1-16,36.6
			c1,5.4,3.4,10.9,6.5,15.3c4.2,5.9,11.2,7.8,18.1,7.9c18.5,0.3,34.7-6.5,49.1-17.6c4.5-3.4,8.5-7.4,12.7-11.1
			c5.1,2.4,5.9,4.1,3.1,8.2c-1.8,2.7-4.1,5.2-6.8,7.2c-5.5,4-11.1,7.9-17,11.3c-9.1,5.4-18.4,10.6-28.9,12.9
			c-9.2,2-18.6,1.9-27.7,5.4c-8.2,3.2-12.9,7.7-14.9,16.2c-4.7,20.1,7.4,32.6,23.7,38c6.7,2.2,15,1,22.3-0.1
			c19-3.1,35.5-11.8,50-24.4C1764.4,911.1,1768,908.2,1771.5,905.2z M86,814c4,4.4,7.2,8.9,11.4,12.3c16,13,34,21.3,55.2,20.6
			c15.7-0.5,24-5.4,28.1-21.3c2.3-9.2,1.2-17.2-6.8-23.1c-4.2-3.1-8.8-6.1-13.5-8.4c-16.3-7.9-30.1-18.7-40.5-33.8
			c-3-4.4-6.6-8.3-10-12.4c-1.4-1.7-3-2.9-5.6-2.1c-5.8,1.8-10.9,4.7-14.9,9.5c-4,4.8-8.4,9.4-12.7,14c-8.4,9.1-15.9,18.8-20.6,30.4
			c-4.7,11.8-10,23.4-14.3,35.4c-2.2,6.3-2.5,13.3-4,19.9c-4.8,22-3.6,43.8,2.7,65.3c2.1,7.1,4.2,14.2,8.7,20.5
			c10,14.1,22.8,25.1,37.6,33.6c8.7,5,17.1,11.2,27.3,12.9c5.3,0.9,10.8,1,16.2,1.2c14,0.3,25.7-4.7,34.2-16.1
			c3.4-4.6,7.2-9.7,5.8-15.9c-2.7-12.2-9.6-21.1-22.2-24.3c-18.4-4.6-36.2-11-53.1-19.7c-9-4.6-17-10.4-23.4-18.3
			c-1.3-1.6-2.9-3.4-0.5-5.5c5.8,3.8,11.8,7,17,11.2c10.1,8.3,21,14.5,33.9,16.9c9.6,1.8,19.1,4.2,29.1,2.4
			c15-2.7,24.2-13.4,27.4-26.5c2.8-11.1-4.9-22.7-17.4-27.6c-6-2.4-12.2-4.3-18.3-6.5c-9.2-3.4-18.6-6.2-27.4-10.5
			c-11.6-5.7-22.6-12.6-30-23.9C83.6,820.8,83.4,817.8,86,814z M201.8,990.1c0.3-3.2,0.5-5.5,0.7-7.8c0.2-2.3,0.4-4.6,0.7-6.9
			c0.9-6.9,1.3-13.3,7.5-18.8c8.7-7.6,15.6-17.2,23.4-25.8c4.8-5.3,7.7-4.7,10.4,1.9c2.7,6.7,5.4,13.4,8.1,20.3
			c4-4.7,4.1-4.7,9.8-2.2c4.3,1.8,5.9,3.9,4.9,8.5c-1.1,4.9-3.3,9.6-5,14.4c-0.4,1.3-1,2.8-0.6,4c1.4,3.9,3.3,7.6,4.7,11.5
			c0.9,2.4,1.6,5,1.5,7.5c-0.1,3.3-1,6.5-1.4,9.8c-0.5,4.4-0.9,8.8-1.2,12.7c17.3,2.3,33.3,7.2,49.6,13.1c0.3-1.7,0.8-3.3,0.9-4.9
			c0.5-7.8,1-15.6,1.3-23.4c0.4-9.3,0.8-18.6,1.2-27.9c0.5-12,0.9-23.9,1.6-35.8c0.5-7.1-2.5-11.7-8.8-14.6
			c-0.9-0.4-1.8-0.9-2.7-1.3c-17.4-8.2-34.8-16.5-52.3-24.5c-12.2-5.6-24.5-11-37-16c-7.7-3-15.8-5-23.7-7.4c-1-0.3-2-0.5-3-0.8
			c0,6,0,11.6,0,17.2c17.2,3.2,24.1,22.2,13.2,35.2c-6,7.1-17.8,9.5-25,0.2c-2-2.5-3.5-5.4-5.8-9c-0.3,2.1-1,3.5-0.7,4.7
			c0.9,4.3,1.6,8.9,3.4,12.8c4.5,9.8,4.4,19.6,0.4,29.3c-1.5,3.8-3.9,7.3-5.8,11c-0.6,1.1-1.5,2.5-1.4,3.6c0.6,4.8,1.5,9.6,2.3,14.7
			C181.4,988,190.1,982,201.8,990.1z M222.1,589.9c0,2.6,0,5.4,0,8.2c0,14,0.1,28,0.1,42c0,4.2-0.4,8.3-0.6,12.4
			c-0.5,10-1.2,19.9-1.3,29.9c-0.1,5.4,0.9,11,5.7,14.2c3.6,2.5,8,4.8,12.2,5.3c8,0.9,16.3,1.4,24.2,0.1
			c16.6-2.7,29.6-11.7,37.4-26.8c7.4-14.2,8.4-29.4,4.9-44.7c-3.6-16-12.2-28.9-26-38.2c-11-7.4-23.4-7.8-36-7.2
			C235.7,585.5,228.8,586,222.1,589.9z M264.5,1028.7c-0.2,3.5-0.3,6.9-0.7,10.3c-0.3,2.5-4.1,5.2-6.6,4.9c-2.8-0.2-5.1-2.6-5.2-5.7
			c0-3,0-6,0.7-8.9c3.3-12.1,5.3-24.2,3.4-36.8c-0.4-2.9-0.9-5.3-3.8-6.3c-9.2-2.9-18.3-5.9-27.5-8.5c-5.7-1.6-8,0.2-8.5,6.2
			c-0.8,8.9-1.6,17.9-2,26.9c-0.2,5.4,0.7,10.9,0.5,16.3c-0.1,1.7-1.9,3.9-3.5,4.7c-3.4,1.7-7.2,1.6-10.2-1.1
			c-2.8-2.5-3.4-6-2.7-9.6c1.2-6.1,3.8-12,2.6-18.4c-3.1-0.9-6-2.1-9.1-2.7c-5.3-1.1-10.8-1.2-15.1,2.5c-2,1.7-4.2,4.6-4.2,7
			c-0.2,12.2,0.3,24.4,0.6,36.5c5.5-5.6,12-6.8,18.4-6c8.5,1.1,16.9,3,25.1,5.4c12.7,3.7,25.2,8.2,37.9,12.1
			c7.6,2.3,10.3,1.1,14.9-5.3c2.7-3.8,6-6.5,10.9-7.1c6.4-0.8,12.6-0.4,18.6,1.9c4.4,1.7,8.9,3.3,13.7,5.1c0.5-3,0.9-5.2,1.2-6.8
			C297.2,1039.8,281,1034.3,264.5,1028.7z M178,1055.4c20.9,7.9,41.3,15.6,61.7,23.3c20.6,7.7,41.8,13.5,62.6,18.1
			c9.3-10.2,6.9-22.6,8.7-33.7c-8.1-4.6-16.3-6.9-25-7.7c-6-0.5-8,0-10.9,5.5c-1.2,2.3-2.2,4.7-3.3,7c-2.3-0.3-4.4-1-6.2-0.6
			c-7.1,1.5-13.8-0.8-20.5-2.4c-10.1-2.4-20.1-5.4-30.2-8.1c-4.6-1.3-10-0.3-13.6-4.6c-0.3-0.3-0.9-0.5-1.4-0.5
			c-5-0.4-9.9-1.1-14.9-1.2C182.3,1050.4,179.6,1051.6,178,1055.4z M204.2,914.5c0.2-7.3-2.7-12.7-7.1-14.9
			c-3.6-1.8-5.1-1.2-7.3,2.3c-1.5,2.5-3.1,5.1-5.1,7.2c-4.1,4.4-4.6,7.9-1,12.7c4.9,6.5,15.3,6.6,18.1,0.6
			C203.2,919.4,203.7,916.1,204.2,914.5z M219.1,958.9c10.8,4.1,20.3,7.6,29.8,11.2c0-4-7.2-25.2-9.9-28.1c-0.8-0.9-3.6-1.5-4.1-0.9
			C229.6,946.7,224.5,952.6,219.1,958.9z'
        />
        <path
          d='M716.8,922.3c0.5,15.2,0.8,26.4,1.3,37.5c0.3,6.4-1.5,12.2-4.4,17.6c-4.1,7.8-13.7,12.1-22.4,10.4
			c-4.8-0.9-9.3-2-13.2-5.3c-5.1-4.3-7.9-9.4-8.4-16.2c-1.1-15.7,1.9-31.1,3.7-46.6c2-17.3,3.2-34.7,5.2-52
			c1.9-17.3,4.4-34.5,6.6-51.8c1.3-10.3,2.8-20.7,3.6-31.1c1.5-20.9,2.5-41.8,3.9-62.7c0.7-9.9,1.8-19.8,3.2-29.7
			c1-7.1,8.3-16.7,20.3-16.5c4.8,0.1,9.6,0.9,14.3,1.3c7.7,0.7,14.6,6.8,13.4,18.1c-1.3,12.9-2.1,25.8-2.9,38.8
			c-0.5,8.3-0.8,16.6-1,24.9c-0.1,5,0.4,10,0.7,15c0.4,7.5,5.9,12.8,13.5,13.3c8.5,0.5,16.1-2.4,22.9-7c16.8-11.6,33.4-23.5,50-35.4
			c12.1-8.7,24-17.8,36.2-26.4c6.7-4.7,13.7-9.3,22.4-8.1c3.5,0.5,7.3,1.5,10.2,3.4c6.3,4.2,10.8,10.3,13.5,17.5
			c1.6,4.3,0.4,8.7-2,12.3c-2.8,4.1-5.7,8.4-9.4,11.5c-9.3,7.7-19.1,15-28.9,22.1c-16.3,11.7-32.4,23.9-49.4,34.5
			c-12.7,8-22.8,18.8-34.1,28.2c-4.9,4-4.8,5.6-1,10.9c11.5,16.1,23,32.2,34.8,48.1c5.9,8,12.7,15.3,18.8,23.2
			c5.8,7.5,11.3,15.2,16.8,22.9c8.3,11.4,16.7,22.8,24.6,34.4c2.4,3.5,3.8,7.7,4.9,11.8c1.6,6.1,0,11.5-4.7,15.8
			c-11.2,10.3-35,13.7-47.5-0.3c-4.7-5.3-8.4-11.5-12.5-17.4c-4.7-6.8-9.1-13.8-13.9-20.6c-14.7-21.2-28.1-43.1-40.7-65.6
			c-6.5-11.6-14.5-22.2-22.2-33.1c-3.2-4.6-7-4.8-11.6-1.6c-4,2.8-6.8,6.3-7.6,11.2C721.2,895.1,718.7,910.7,716.8,922.3z'
        />
        <path
          d='M518.9,801.2c-0.2,1.1-0.7,2.4-0.7,3.6c-0.4,12.1-0.8,24.3-1.1,36.4c-0.4,18.3,0.7,36.8-1.4,54.9
			c-2.1,18.1-13.8,28.5-31.8,25.9c-13.3-2-24.6-8.8-32.6-20.2c-5.6-7.9-6-16.5-4.3-25.6c4.6-24.2,9.3-48.3,16.9-71.8
			c0.4-1.3,0.7-2.5,1.1-3.8c2-6.3,1.8-7.4-3.6-11c-11.2-7.4-19.6-17.3-27.3-28.1c-8.5-11.9-18.1-23.2-22.8-37.3
			c-3.3-9.8-5.3-20.1-7.3-30.3c-1.7-8.8-2.5-17.7-3.4-26.6c-1.6-15.9-0.3-31.5,5.8-46.5c2.8-6.9,5.7-13.9,9.5-20.3
			c2.9-4.9,6.7-9.6,11.1-13.3c8-6.7,25.9-5.3,33.4,2c5.3,5.1,5.5,11.6,4.3,18.1c-2.2,11.7-5.7,23.2-7,35c-1.9,18-2.2,36.4,3.7,53.8
			c7.1,20.8,21.9,32.7,43.6,37.1c17.8,3.6,32.4-1.1,46.1-12.6c15.7-13.1,24.6-30.3,32.5-48.3c4.4-10,7.4-20.6,11.2-30.8
			c1-2.8,2.3-5.6,4.1-7.9c6.3-8.2,14.7-8.7,23.8-5.9c13.5,4.1,19,11.8,19.3,26.1c0.2,7.7-2.2,14.7-4.3,21.8
			c-1.5,5.2-3.2,10.4-5,15.6c-7.1,20.5-15.6,40.2-29.9,56.9c-5.4,6.3-10,13.4-15.8,19.3c-15.2,15.3-33.3,25.5-54.7,29.9
			C528.1,798.2,523.9,799.7,518.9,801.2z'
        />
        <path
          d='M223.1,304.7c8.6-5.8,13.9-5.7,23.8,10c6.8-4.9,12.6-2.1,19,1.8c4.3,2.7,9.9,3.5,15,4.8c1.2,0.3,2.9-0.5,4.1-1.2
			c2-1.2,3.8-2.7,5.7-4c6.2-4.1,11.4-3.8,16.5,1.6c2.7,2.9,5,6.2,7.4,9.4c3,3.9,3.5,4,7.6,1.3c1.5-1,3-2.1,4.5-3.1
			c4.8-3,10-2.8,14.3,0.8c2.3,1.9,4.3,4.1,6.1,6.5c2.7,3.7,5.6,6.6,10.7,5.8c0.3-0.1,0.7-0.1,1,0.1c8,3.8,16.9,6.4,21,15.4
			c0.3,0.7,0.6,1.6,1.2,2.1c3.7,3.5,4.4,8,1.8,11.3c-3.1,3.9-8.2,4.5-12.3,1.1c-2-1.6-3.3-4.1-4.9-6.1c-1.3-1.6-2.6-3.4-4.1-4.8
			c-3.1-2.9-6.4-4.1-11.1-3.3c-5.6,1-10.7-1.9-14.4-6.4c-1.9-2.3-3.5-4.8-5.4-7.4c-6.5,4.6-13.1,8.1-21.2,4.1
			c-7.3-3.6-9.7-11-13.5-18.1c-5.9,6.6-12.5,9.6-21.3,8.2c-8.2-1.3-15.3-4.1-20.9-10.2c-0.8,0.3-1.3,0.4-1.6,0.7
			c-5.9,5.6-12.4,4-16.8-3.4c-1.8-3-3.3-6.1-4.9-9c-0.7,0.1-1.1,0-1.3,0.2c-6.7,7-11.7,5.3-18,0.6c-4.9-3.7-9.5-7.9-14-12.1
			c-2.6-2.4-4.7-2.4-7.4-0.2c-3.1,2.5-6.6,4.1-10.5,2c-3.9-2.2-5.2-5.8-5.2-10.1c0-4.3,0-8.7,0.2-13c0-1.1,0.2-2.5,0.8-3.3
			c1.2-1.6,2.9-4,4.3-3.9c1.8,0.1,4.3,2,5,3.7c1.3,3,1.4,6.4,1.9,9.7c0.2,1.1,0.2,2.2,0.4,3.9c10.5-6.5,18.4-1.1,25.9,5.1
			C216.1,298.2,219.3,301.3,223.1,304.7z'
        />
        <path
          d='M724.1,1268.1c12.1-3.5,13.7-2.8,22.8,10c5.9-2.8,12.1-4.9,18.8-1.5c6.4,3.2,9.6,8.9,12.9,15c3.4-0.6,6.6-1.3,9.8-1.8
			c10.3-1.5,18.3,2.3,23.9,10.9c1.9,2.8,3.8,4.9,7.3,4.9c0.2,0,0.4,0,0.5,0.1c6.6,3.8,13.5,7.2,19.7,11.5c3.6,2.5,4.8,6.4,5.7,12.3
			c-2.3-0.9-4.3-1.4-6-2.4c-3.4-1.9-6.7-2.5-10.4-1c-5.2,2.1-9.7,1.1-13.4-3.3c-0.7-0.9-2.1-1.6-3.2-1.7c-5.9-0.6-10.2-3.5-13.6-8.3
			c-4.4-6.3-10.1-9.1-18.1-7.1c-6.8,1.7-12.4-1.1-16.4-6.8c-1.5-2.2-2.8-4.5-4.3-6.7c-2.5-3.6-3.8-3.8-7.4-1.7
			c-0.7,0.4-1.4,0.9-2.1,1.3c-6.6,4.1-12.3,3.2-17-2.9c-1.5-1.9-2.8-4.1-4.1-6.1c-11.1,9.4-17.8,1.9-22-10.3
			c-0.3,0.1-0.7,0.1-0.9,0.4c-4.4,6.9-11.1,4.9-14.6-1.4c-1.7-3-3.1-6.2-4.9-9.8c-2,1.6-3.7,3-5.5,4.4c-2.1,1.6-4.1,3.4-6.3,4.9
			c-2.5,1.7-5.3,2.5-7.7-0.1c-2-2.1-1-5,1.9-7.7c3.1-3,6-6.2,9.1-9.2c1.5-1.5,3.2-2.9,5-4.1c4.9-3.4,9.9-2.8,13.7,1.8
			c2.1,2.5,3.5,5.5,5.3,8.3C711.5,1253.8,715.3,1255.3,724.1,1268.1z'
        />
        <path
          d='M457.4,307.6c-9.9,5.1-16.3,2.9-21-6.8c-1.5-3.2-2.6-6.7-3.8-10.1c-16.1,8.6-24.6,2.6-33.2-9c-8.6,4.8-16.1-0.7-24-2.9
			c-1.3-0.4-3.1-2.9-2.9-4.1c0.3-1.8,1.9-4.5,3.4-4.9c3.6-0.9,7.4-0.7,11.2-1c2.4-0.2,5,0.1,7.1-0.7c7.9-3.3,13.9-2.2,19.8,4
			c0.7,0.7,1.4,1.4,1.9,2.3c3.6,5.9,8.4,7.7,15.2,5.4c4.7-1.6,9.2,0.1,12.7,3.7c2.2,2.2,4.1,4.8,6.3,7.4c0.4-0.1,1.1-0.2,1.6-0.5
			c6.5-3.6,12.3-2.3,18.2,1.7c3.5,2.4,7.5,4.1,11.4,5.7c1.4,0.6,3.3,0.5,4.9,0.2c7-1.4,12.4,1.4,15.5,7.2c3.1,5.8,5.1,12.2,7,18.5
			c0.8,2.7-2,4.3-4.5,4.9c-3.8,0.8-7.2-0.4-10.1-3c-1-0.9-2.1-1.8-2.6-3c-2-4.2-5.2-4.7-9.6-5C472.9,316.8,464.1,314.8,457.4,307.6z
			'
        />
        <path
          d='M467.1,1089.9c-1.1,0.9-2,1.6-2.9,2.3c-6.3,4.9-11.3,4.1-16-2.5c-1.5-2.1-3.5-3.9-5.4-5.7c-2.4-2.3-4.9-2-7.8-0.6
			c-11.3,5.6-14.5,4.9-22.4-4.8c-0.4-0.5-0.8-1.1-1.3-1.6c-3.3-3.8-3.7-3.8-7.5-0.5c-5.6,4.9-9.3,4.8-14.5-0.7
			c-1.7-1.8-3.2-3.8-4.8-5.8c-0.5,0.3-1.1,0.4-1.4,0.7c-1.7,1.8-3.5,3.4-6,1.8c-2.5-1.6-2.8-4.1-1.4-6.6c1.5-2.7,3.1-5.4,4.9-7.9
			c0.5-0.7,2.4-1.1,3-0.7c5.1,3.6,10.1,7.4,15.7,11.5c0.4-0.2,1.6-0.8,2.7-1.4c5.9-3.5,9.5-3.3,15.1,0.9c1.6,1.2,3.1,2.5,4.6,3.9
			c4.7,4.3,4.7,4.4,10.1,1.4c7.4-4.2,14.3-3.5,20.8,1.7c2.2,1.7,3.8,4,6,6.3c0.6-0.3,1.3-0.7,1.9-1.1c7.5-5.1,12.4-4.7,19.1,1.5
			c0.8,0.8,1.7,1.7,2.3,2.6c5,7.2,12,10.8,20.7,11.1c6.3,0.3,11.4,3.2,16,7.2c3.2,2.9,6.4,4.3,11.2,3.3c9.5-2,20.3,9.2,19.1,18.9
			c-0.6,4.7-4.4,6.7-8.8,4.2c-2.4-1.3-4.5-3.5-6.1-5.7c-2-2.7-4.1-4-7.5-3.5c-4.5,0.7-8.9,0-12.2-3.1c-6.4-6.1-13.9-9-22.7-9.7
			c-9.5-0.7-16.4-5.8-21.3-13.7C469.2,1092.5,468.2,1091.3,467.1,1089.9z'
        />
        <path
          d='M258.1,211.4c-1.5,1.9-2.3,3-3.3,3.9c-5.9,6-10.3,5.7-15.9-0.7c-3.2-3.6-6.4-7.2-10-10.4c-4.7-4.2-10-5.9-16.5-3.9
			c-7.3,2.3-12-0.2-15.3-7.2c-1-2.1-1.7-4.2-3.7-6.6c-1.4,2.7-3,5.2-4.2,8c-1.3,2.9-3,5.1-6.4,4c-3.2-1-3.6-3.8-3.1-6.9
			c0.8-5.8,3-10.9,6.9-15.1c5.4-5.8,12.6-5.8,17.8,0.1c2.8,3.2,4.7,7.1,7.2,11c16.3-1.5,28,6.2,36.7,19.9c1.6-1.5,2.7-2.6,4-3.7
			c5-4.5,10-4.6,15.1-0.1c1,0.9,2.1,1.8,2.7,2.9c1.9,3.7,4.9,4,8.6,4c9.5,0.1,18.2,3,25.8,8.8c1.3,1,2.5,2.4,3.1,3.8
			c1.4,3.3,3.9,4.5,7.2,5.1c8.3,1.6,13.4,6.9,15.7,14.8c0.6,2,0.6,5.5-0.5,6.3c-1.6,1.1-4.5,0.5-6.9,0.4c-0.8,0-1.6-0.5-2.2-1
			c-4.9-3.9-10.2-7.2-16.1-9.6c-2.7-1.1-4.4-4.3-6.8-6.4c-1.8-1.6-3.8-3-5.9-4c-2.4-1.1-5.1-1.5-7.5-2.7c-3.8-1.8-7.7-2.9-12-2.3
			c-4.3,0.6-7.3-1.7-9.6-5C261.6,216.7,260.2,214.5,258.1,211.4z'
        />
        <path
          d='M634.3,1045.1c-8.4,6.7-11.7,6.8-20.4,0.7c-1.1-0.8-2.2-1.5-3.3-2.3c-6.2-4.5-12.9-6.5-20.6-4.3c-2.8,0.8-5.9,0.8-8.8,1
			c-4.5,0.3-7.5-2.1-9.8-5.7c-1.6-2.6-3.3-5.2-4.6-8c-0.5-0.9-0.3-3.1,0.3-3.5c1.5-1,3.9-2.2,5.2-1.6c2.2,1,4.3,3.1,5.7,5.2
			c3.2,4.9,3.7,5.2,9.2,3.4c4.4-1.4,8.8-3.2,13.3-3.9c7.7-1.3,14.8,1.4,21.3,5.3c2.6,1.5,4.3,1.8,7-0.2c7-5.1,13.8-3.8,18.8,3.5
			c2.3,3.4,4,7.2,5.8,11c1.1,2.5,2.5,4.1,5.4,4.5c2.9,0.4,5.8,1.1,8.7,1.9c6.3,1.6,12.5,3.8,16.9,8.9c5.9,6.9,13.3,10.1,22,11.1
			c4.5,0.5,9,1.7,12.9,5.4c-3.9,4.8-9.3,5.9-14.5,6.6c-11.7,1.5-22.1-1.3-30.1-10.6c-5-5.7-11.2-8.5-18.9-8.4
			c-9.8,0.1-16.4-4.4-19.3-14C635.9,1049.2,635.1,1047.3,634.3,1045.1z'
        />
        <path
          d='M440,982.8c-2.7,1.6-4.7,2.7-6.7,3.9c-6.1,3.8-10,3.4-15-1.8c-0.7-0.7-1.3-1.5-1.9-2.3c-5.5-7.5-6.5-7.7-14.4-2.6
			c-0.6,0.4-1,0.8-1.6,1.2c-6.6,4.2-11.3,1.7-11.5-6.1c-0.1-3.2,0-6.3,0.1-9.5c0.1-3.1,1.2-5.8,4.6-6.2c3.6-0.4,4.6,2.7,5.4,5.4
			c0.4,1.2,0.3,2.6,0.5,4.3c0.9-0.2,1.7-0.3,2.4-0.6c10-4,16.8-2.4,22.9,6.3c2.4,3.5,4.3,3.4,7.6,1.7c7.7-4,12.4-3.1,18.9,2.9
			c2.1,1.9,3.9,4.1,5.7,6.2c1.9,2.3,4.2,3,7.1,2.3c3.1-0.7,6.2-1.1,9.2-2c6.5-1.9,12.5-0.1,18.3,2.7c4.9,2.4,9.8,4.8,14.8,7.2
			c4.7,2.3,8.9,5,10.8,10.3c1,2.7,0.3,3.5-2.6,4.3c-12.4,3.4-23.5,1.3-33.7-6.6c-4.7-3.7-10.5-3-15.9-2.2
			c-8.7,1.3-14.7-2.3-19.1-9.5C444.1,989.2,442.2,986.3,440,982.8z'
        />
        <path
          d='M614.7,1148.6c-2.1,1.3-3.9,2.5-5.7,3.5c-3.4,2-7.1,1.3-9.2-1.8c-2.5-3.7-2.7-7.9-1.2-11.9c0.5-1.4,2.4-2.9,3.9-3.1
			c1-0.2,2.6,1.6,3.5,2.8c0.8,1,1.1,2.4,1.7,3.8c1.1-0.4,2.2-0.8,3.2-1.2c6.4-2.4,11.8-0.5,16,4.5c2.9,3.4,6.2,5.3,10.8,5
			c1.7-0.1,3.7,0.3,5.3,1.1c4.3,1.9,8.7,2.6,13.4,3.1c3,0.3,6.5,2.7,8.5,5.2c3.3,3.9,5.5,8.6,8.4,13.2c0.6-0.5,1.3-1.1,1.9-1.7
			c6.5-6.8,15.3-6.6,21.4,0.8c1.8,2.1,3.2,4.6,4.1,6c6.2,0.5,11.9,0.4,17.2,1.5c3.5,0.7,6.8,3,10.3,4.7c-2.1,5.8-6.7,7.2-11.2,8.1
			c-5.8,1.1-11.8,1.6-17.7,2.2c-5.2,0.5-9.1-2.6-11.8-8.9c-1-2.5-2.5-4.7-4.1-7.8c-2.4,2.2-3.9,3.6-5.4,5.1
			c-4.4,4.3-8.9,4.4-12.8-0.4c-3.5-4.2-6.4-8.9-9.2-13.6c-1.6-2.7-3-3.8-6.5-3.9c-7.4-0.3-14.9-1.2-22.1-3c-3.2-0.8-5.9-4-8.5-6.5
			C617.4,1153.5,616.4,1151.1,614.7,1148.6z'
        />
        <path
          d='M335.4,449.5c1.8-0.4,4-0.7,6.2-1.3c9.4-2.5,17.1,0.3,22.9,7.8c2.5,3.2,4.2,5,8.8,2.8c5.7-2.8,11.4-0.7,16,3.4
			c2.1,1.9,4.2,3.9,5.6,6.3c1.5,2.7,3.5,3,6.2,2.8c10.7-0.9,20.6,6.9,22.3,17.6c0.2,1.1-1.1,3.4-1.9,3.6c-3.2,0.4-6.5,0.7-9.6,0
			c-2.3-0.5-4.4-2.2-6.4-3.7c-2.9-2.2-6-3.5-9.7-3.2c-5.8,0.5-9.8-2.4-12.8-7c-0.5-0.8-1-1.7-1.5-2.6c-4.1-6.7-4.1-6.7-11.3-4.1
			c-3.9,1.4-7.6,0.8-10.8-1.7c-2.6-2-5.1-4.3-7.2-6.8c-3.4-4.2-7.3-5.4-12.3-3.6c-2.2,0.8-4.4,1.6-6.6,2.1c-4.9,1.1-9.6-2-10.2-6.9
			c-0.6-5.4-1-11-0.9-16.4c0-1.6,1.5-4.1,2.8-4.5c1.3-0.4,4.2,0.7,4.9,2C332,440.2,333.5,444.7,335.4,449.5z'
        />
        <path
          d='M783.4,1104.1c4.5-1.8,8.5-4.2,12.9-4.9c7-1.2,12.4,2.9,17.3,7.3c2.8,2.6,5.4,3.2,9,1.8c8.1-3.1,15.7-2.2,21.9,4.5
			c2.6,2.8,5.6,3.3,9,1.5c0.9-0.4,1.8-0.8,2.7-1.2c7.6-3.4,11.8-2,16.2,5c2.5,4,5.3,7.9,8.3,12.2c-1.4,4-3.5,5.8-7.7,5.8
			c-8.4,0-10.6-6.7-13.5-12.7c-3.1,0.9-6.2,2.2-9.4,2.7c-6.4,1.1-12.3-0.5-16.8-5.4c-2.4-2.6-4.9-3.3-8-1.6
			c-8.2,4.7-15.2,2.6-21.5-3.8c-0.8-0.8-1.7-1.6-2.7-2.2c-6.9-5-8.8-5.5-14.3,0.5c-3.5,3.8-7.9,3.5-11.2-0.5
			c-4.2-5.1-6-11.3-6.6-17.9c-0.3-3,0.9-5.3,3.9-6.1c3-0.8,4.8,1.1,5.9,3.5C780.4,1096.5,781.9,1100.5,783.4,1104.1z'
        />
        <path
          d='M545.4,1204.6c10.1-4.6,18.1-0.1,25.2,7.3c8.1-2.8,11.6-1.8,15.7,5.6c2,3.6,4.6,5.2,8.6,5.5c5.8,0.4,10.5,3.5,15.3,7.2
			c-5.4,7.3-12.9,5.9-20,5.9c-8.5,0-13.5-4.5-15.9-12.9c-5.5,3.7-9,1.5-13-2.6c-3.5-3.6-8.4-5.9-14-3.7c-5.1,2-9.4,0.8-12.4-4
			c-2.6-4-4.7-8.3-7.2-12.9c-11.6,10.6-15.9-0.5-21.7-7.9c-2.1,2-3.7,3.9-5.7,5.2c-1.4,0.9-3.5,0.9-5.2,1.3c-0.2-1.7-0.4-3.3-0.4-5
			c0-0.7,0.6-1.5,1-2.2c2.7-4.8,6.1-9.1,12.2-8.9c5.9,0.2,8.7,4.8,10.9,9.6c0.5,1.2,1.1,2.4,1.9,4.1c4.4-2.7,8.9-6.8,13.9-3.1
			C538.5,1196.1,541.5,1200.4,545.4,1204.6z'
        />
        <path
          d='M516.9,402.8c-11,4.6-20.3,1.8-28.7-4.8c-3.1-2.5-5.5-3-8.8-0.7c-7.1,4.8-12.9,4.1-19.7-2.2c-2.3-2.1-4.4-4.6-6.7-6.7
			c-2.3-2.2-4.8-3.4-8.1-2c-2.4,1-5.1,1.6-7.7,2.1c-3.5,0.7-6.7-0.1-8.9-3.1c-2.2-3-1.9-6.4-0.1-9.4c0.9-1.5,2.7-3,4.4-3.4
			c2.5-0.7,3.2,1.6,4,3.5c0.2,0.4,0.5,0.8,1.1,1.6c1.6-0.1,3.4,0,5.1-0.5c7.9-2.2,14.7,0,21.1,4.5c1.2,0.9,2.5,1.7,3.6,2.7
			c2.7,2.4,5.4,2.8,8.6,0.9c8.1-4.9,15.5-3,22.6,2.4c1.3,1,2.5,2.2,3.8,3.2c3.6,2.9,7.1,3.4,11.4,0.8c6.3-3.8,11-2.6,15.1,3.1
			c3.9,5.3,7.7,10.7,11.6,16.1c2.1,2.9,1.2,6-2.3,6.5c-2.2,0.3-5,0.1-6.6-1.1C526.2,412.4,519.8,409.7,516.9,402.8z'
        />
        <path
          d='M791.8,1211.9c1.6-1.3,2.8-2.3,4.1-3.3c7.3-5.7,14.4-5.8,21.9-0.2c3,2.3,5.9,4.9,8.7,7.5c1.9,1.8,3.5,2.5,6.2,1.3
			c8.3-4,16.6-3.2,25,0.2c6.4,2.6,13,4.5,19.8,6.8c-0.1,4.7-6.1,10.3-12.4,10.9c-5.7,0.5-11-0.7-15.8-3.5c-5.2-3.1-9.6-3-14.6,0.8
			c-4.9,3.7-10.6,2.9-15.4-0.5c-4.1-3-7.8-6.7-11.3-10.5c-4-4.3-4.6-4.7-8.6-0.4c-5.2,5.6-11,5.3-15.2,0.9
			c-3.3-3.5-4.1-10.9-1.4-14.9c0.8-1.2,2.5-2.7,3.7-2.7c1.2,0.1,2.4,1.9,3.3,3.2C790.7,1208.7,791.1,1210.2,791.8,1211.9z'
        />
        <path
          d='M174,395.6c11.9-6.9,20.5-3.1,28.9,4.4c0.9,0.8,1.8,1.6,2.4,2.5c2.3,3.3,4.5,4.4,8.7,2.3c4.5-2.2,9.2-0.6,12.6,3.2
			c2.5,2.9,4.7,6,6.8,9.2c1.2,1.8,2.3,2.4,4.5,1.8c8.9-2.7,17.9-1,26.6,0.9c6.1,1.4,8.1,4.5,2.5,10.2c-4.1,4.2-9.5,5.3-15.2,5
			c-6.1-0.2-12-1.9-18.3-0.9c-3.9,0.6-7.7-1.8-10.1-5.3c-2.3-3.3-4.2-6.7-6.1-10.2c-1.2-2.3-2.3-3.4-5.4-2.5c-5.9,1.7-11-1-15.4-4.9
			c-2.4-2.1-4.5-4.4-6.9-6.5c-3.8-3.3-7.9-3.8-12.3-1.3c-1.6,0.9-3.3,1.6-5,2.2c-2.6,0.8-4.9,0.3-6.4-2.3c-1.4-2.5-0.3-5.9,2.3-8.5
			C170.4,392.5,172.2,393.5,174,395.6z'
        />
        <path
          d='M568.1,527.3c-0.1,3.6-3.3,4.6-6,5.1c-3.5,0.5-7.2,0.2-10.8,0c-2.8-0.1-5.6-0.9-8.3-0.7c-5.9,0.4-9.8-2.5-12.8-7.1
			c-1.5-2.3-2.9-4.7-4.4-7.3c-2.9,1.2-5.3,2.3-7.8,3.1c-7.4,2.4-13.7,0.6-18.9-5.1c-0.6-0.6-1.1-1.2-1.7-1.9c-4-4.4-4.4-4.4-9.5-1.2
			c-2.9,1.8-5.7,3.5-9.3,1.5c-3.6-2-4.9-5.3-4.7-9.2c0.2-2.4,0.3-5,1.3-7.2c0.6-1.4,2.6-2.9,4-2.9c1.4,0,2.9,1.7,4,2.8
			c0.7,0.8,1,2,1.6,3.2c0.9-0.4,1.7-0.7,2.4-1.1c10.3-6.1,18.4-2.1,23.8,3.7c2.9,3.2,6,4.1,9.7,3.2c8.5-2.2,14.6,1.5,19.2,8.1
			c2.7,3.8,5.2,6.8,10.6,6.4c2.9-0.2,6,1.7,8.9,2.8C562.2,524.6,565,525.9,568.1,527.3z'
        />
        <path
          d='M421.2,1159c-8.2,1.5-14.8-0.9-20.2-6.7c-1.6-1.7-3.1-1.7-4.6,0.1c-0.7,0.8-1.3,1.5-2,2.2c-4.4,4.5-9.4,4.3-12.9-1
			c-1.8-2.7-2.9-5.9-4.3-8.9c-0.4-0.9-0.8-1.8-1.4-3.3c-1.4,3.3-2.2,6.2-3.7,8.6c-1.2,2-2.9,4.1-4.9,5.2c-1.3,0.7-4.1,0.2-5.2-0.8
			c-1.1-1-1.8-3.8-1.2-5.2c2.5-5.6,5.2-11.1,8.5-16.3c4.5-7,10.7-6.5,14.6,0.8c2,3.8,3.5,7.8,5.4,12.1c1.2-1,2.2-1.7,3.1-2.5
			c5.4-4.5,10.1-4.8,15.8-0.8c1.8,1.2,3.3,3,5.2,3.8c1.8,0.8,4.1,1.3,6,0.9c7-1.3,10.6,0.2,13.8,6.5c0.8,1.6,1.2,3.5,2.1,5
			c1.1,1.9,2.5,3.5,3.8,5.3c0.4-0.2,0.8-0.4,1.1-0.5c-0.2,1.4-0.1,2.9-0.6,4.3c-2,6.5-7.4,8-12.1,3.2c-1.9-2-3.2-4.6-4.5-7
			C422.3,1162.8,422,1161.1,421.2,1159z'
        />
        <path
          d='M592.6,432.5c-3.1,0.3-7.2,0.6-11.3,1.2c-6.8,1-12.4-5.6-10.2-13.7c1.1-4,5.4-5.6,8.5-3.1c2,1.6,3.7,1.4,5.9,0.9
			c7.7-1.7,14.9,1.2,19.1,7.5c2.6,3.9,2,5.3-2.6,6.7c-0.6,0.2-1.3,0.5-1.9,0.5C598,432.5,595.8,432.5,592.6,432.5z'
        />
        <path d='M891,1373.9c6.6-1.6,12.8,0.1,18.9,1.5c2.1,0.5,2.7,2.2,1.4,4.6C903.8,1380.8,897.4,1377.2,891,1373.9z' />
        <path
          d='M1250.3,744.4c1.2,4.2,2.4,8,3.5,11.8c5.3,18.8,9.3,37.9,12.2,57.3c2.8,18.6,5.3,37.2,4.9,56c-0.4,18.5-1.8,37-7.9,54.5
			c-9.6,27.1-26.4,48.5-52.2,62.4c-9.6,5.2-18.5,11.8-29.4,14.2c-21,4.7-41.5,5.1-61.8-3.6c-8.9-3.8-15.9-9.6-22.8-15.7
			c-8.1-7.1-16.1-14.7-22.4-23.4c-6.8-9.3-12.1-19.8-17.4-30.1c-6.4-12.2-8.9-25.4-9.5-39.3c-0.3-8.7-1.1-17.2,1.3-25.7
			c3.4-12.3,10.9-20.2,23.8-22.4c10.8-1.9,21.1,5,22.8,15.6c0.2,1.4,0.2,3,0,4.4c-3.2,21.4,1.9,40.8,14.2,58.3
			c2.1,3.1,5.3,5.5,8.4,7.7c5.8,4.4,11.8,8.4,19,10.6c9,2.8,17.8,0.9,26.7,0.3c4.7-0.3,9.4-2,13.9-3.4c11.9-3.8,20.1-11.6,25.4-23
			c6.2-13.4,9.4-27.2,9.8-41.9c0.8-28.6-2.1-56.8-9.7-84.4c-1.7-6.2-4-12.2-6.1-18.3c-1.2-3.3-3.6-4.7-7.1-4
			c-6.3,1.3-12.6,2.7-18.9,4.1c-11.9,2.6-23.9,5.1-35.8,7.9c-8.4,1.9-16.6,4.4-25,6.3c-7.1,1.7-14.2,3-21.4,4.5
			c-8,1.6-14.7-1.2-19.6-7.1c-11.7-14.1-7.7-30.8,7.9-36.8c17.2-6.7,34.8-12.8,52.6-17.7c23.9-6.5,48.3-11.4,72.4-17.2
			c16.8-4,33.4-8.3,50.2-12.4c18.3-4.5,36.6-8.9,54.8-13.7c5.8-1.5,11.3-3.5,17.4-3c10.2,0.8,17,6.8,21.7,14.8
			c4,6.9-1.8,19.2-10.7,23.8c-6.6,3.4-13.6,6.2-20.6,8.5C1293.7,731.1,1272.3,737.6,1250.3,744.4z'
        />
        <path
          d='M1607.1,751.4c1.3,18.9-7.5,38.4-19.2,57.4c-8.9,14.6-20.7,25.6-36,32.6c-8.3,3.8-17.3,6.3-26.2,8.8
			c-6.8,1.9-13.8,3.7-20.8,4.3c-17.7,1.6-34.5-1.8-50.3-10.2c-12.3-6.6-23.3-14.7-32.7-25.1c-2-2.2-3.9-4.5-5.5-7
			c-10.7-15.9-16.2-33.6-18.7-52.5c-3.5-26.1,2.8-50.2,13.3-73.8c9.8-21.9,27-35.7,48.1-45.6c9.8-4.6,20-8.2,30.7-9.5
			c8-1,16.3-0.2,24.4-0.6c8.3-0.3,16.3,1.1,23.8,4.4c19.3,8.5,35.3,21,47.4,38.5C1600.3,695,1607.4,722.8,1607.1,751.4z M1549,750.5
			c-0.7-6.6-1.3-13.2-2.2-19.7c-1.6-12.1-7.6-22.4-14.8-31.7c-8-10.4-18.4-17.8-32.7-16.7c-2.6,0.2-5.2,0.6-7.9,1
			c-8.6,1.3-16,5-20.7,12.4c-9.1,14.5-12.1,30.7-11.9,47.6c0.1,4.8,1.2,9.5,1.8,14.3c1.7,13.4,6.5,25.3,15.9,35.2
			c10.3,10.9,22.7,16.3,37.7,14.1c13.1-1.9,21.7-10.4,27.4-21.7C1547,774.5,1548.9,762.7,1549,750.5z'
        />
        <path
          d='M1172.7,1040.1c0.7,13.7,1.4,26.8,2.1,39.9c0.8,14.9,1.7,29.9,2.4,44.8c0.4,8.5,0.3,17,0.6,25.4
			c0.8,19.1,1.7,38.2,2.5,57.3c0.2,3.7,0,7.3,0.1,11c0.1,12.3,0,24.6,0.5,36.9c0.2,4.7-1.4,7.4-5.5,9.3
			c-11.9,5.4-23.7,11.2-35.8,16.2c-29.5,12.2-59,24-88.6,35.9c-8,3.2-16.1,6.2-24.2,9.2c-5.4,2-10.9,3.8-16.4,5.6
			c-7,2.2-12.4-0.9-13.6-8.1c-0.9-5.4-1.6-10.8-2-16.3c-0.9-14.4-1.8-28.9-2-43.3c-0.3-19.7,0.1-39.5-0.2-59.2
			c-0.1-8-1.1-15.9-1.5-23.9c-0.2-3.8-0.3-7.6-0.3-11.5c0.3-20.1,0.5-40.3,1-60.4c0.1-4.9,1.1-9.9,2.1-14.7c1-4.2,3.8-7.1,8-8.8
			c20.4-8.5,41.7-14.1,63-19.9c26.2-7.2,52.4-14.6,78.6-21.6c7.2-1.9,14.5-3.3,21.9-4.5C1167.6,1039,1170.1,1039.8,1172.7,1040.1z
			 M1020.9,1174.1c2.5-0.2,5.1-0.3,7.7-0.8c5.1-1,7.2,0.2,7.1,5.3c-0.2,8.3-1.2,16.5-1.5,24.8c-0.2,5.4,0.2,10.9,0.8,16.2
			c0.1,1.2,2.4,3.2,3.6,3.1c1.6,0,3.4-1.4,4.8-2.6c0.7-0.6,0.9-2.1,1.1-3.2c2.9-13.4,7-26.1,18.2-35.4c1.9-1.6,5-3.8,4.5-7.6
			c-1.3-10.7-2.5-21.3-3.6-32c-0.2-1.9-0.4-3.1-2.2-4.4c-2.9-2.1-3.6-5.5-2.4-9c1.5-4.3,3.4-8.5,4.9-12.2c-1.7-2.3-3.7-4.4-4.9-6.8
			c-1.2-2.3-0.3-5,2.1-5.8c1.4-0.5,3.5,0.8,5.1,1.6c0.7,0.4,1.1,1.5,1.9,2.6c3.9-2.5,7.6-4.9,11.4-7.3c0.1-2.2,0.2-4.3,0.3-6.4
			c0.4-0.2,0.7-0.5,1.1-0.7c2.1,2,4.2,4,6.2,6.1c3.5-4,5.9-8.9,10.8-11.4c0.4,0.2,0.7,0.5,1.1,0.7c-1.4,4.3-2.9,8.6-4.3,12.9
			c3.2-0.9,6.3-1.8,9.9-2.8c0,2.7,0,4.8,0,6.9c0.3-0.2,0.6-0.3,0.9-0.5c2.7,3.3,6.7,6.1,7.9,9.9c2.1,6.2,2.5,12.9,3.4,19.4
			c0.1,1-1.4,2.3-2.1,3.4c-0.6,0.8-1.4,1.6-1.6,2.5c-0.1,0.4,1.2,1.3,2,1.6c4.5,1.7,6.9,4.2,6.7,7.5c-0.3,4-2.6,6.3-6.4,7.3
			c-0.5,0.1-0.9,0.4-1.1,0.5c-0.4,3.3-0.6,6.4-1.3,9.5c-1.5,6.1-0.6,8.2,4.3,12.2c2.9,2.3,5.3,5.3,7.6,8.3
			c7.4,9.9,12.3,21.1,17,32.4c0.9,2.1,1.8,4.3,3,7.2c3-5.2,5.5-9.6,8-13.8c2.5-4.2,6-4.5,9.3-0.9c1.4,1.5,2.7,3.1,4.1,4.7
			c0.3-0.1,0.6-0.3,0.9-0.4c0-22.4,0-44.8,0-67.2c-5.5,0.6-10.9,1.2-16.3,1.8c-3.3,0.3-4.4,2.8-5.1,5.4c-1.1,4.4-3.1,8-8,9
			c0,1.3,0,2.3,0,3.3c0.2,6.7,1.3,7.7,8.1,7.2c1.5-0.1,2.9-0.9,4.4-0.8c3.2,0.2,6.9-1.7,9.5,1.7c-3.2,6.4-16.7,9.2-22.5,6.3
			c-1.9-0.9-3.9-2.6-4.9-4.5c-3-5.5-3-11.5-1.4-17.4c0.4-1.6,2.4-3.8,3.9-4c3-0.3,3.7-2.1,4.6-4.3c1.7-4.4,4.7-8,9.4-8.8
			c6-0.9,12.1-0.8,18.4-1.2c-1.2-29-2.4-58.6-3.7-88.8c-2.5,0.2-4.5,0.2-6.4,0.6c-9.3,2.3-18.6,4.8-27.9,7.3
			c-25.7,7-51.5,13.8-77.1,21.1c-12.3,3.5-24.4,7.5-36.5,11.5c-9.1,3-11.6,6.6-11.8,16.1c-0.1,5-0.5,10-0.5,15
			c0.1,14.8,0.4,29.5,0.6,44.3c0,1.2-0.1,2.3-0.2,3.5c-0.1,3.6-0.3,7.2-0.2,10.8c0,3.3,0.8,6.7,0.3,9.9c-2.3,18,0.4,35.7,1.5,53.7
			c1.9-2,3.3-4.2,4.7-6.3c2.7-4.1,5.2-7.8,3-13.4c-1.5-3.7-1.4-8.2-1-12.3c1-10.7,1.8-21.5,4.1-31.9c1.8-8.2-1-14.3-5.3-20.5
			c-1.3-1.9-3-3.8-3.5-5.9c-0.6-2.4-0.8-5.5,0.2-7.6c0.6-1.2,4-1.1,6.2-1.7c-0.7-2.6-2.6-6.3,0.9-8.1c3.1-1.6,5,1.4,6.4,4
			c0.1,0.2,0.3,0.3,0.6,0.6c0.5-1.5,0.5-3.2,1.4-4.2c1.3-1.7,3.2-4.1,4.8-4.1c2.4,0,4.1,2.3,4.3,5.2c0.2,3.8,0.9,7.5,1.4,11.4
			c0.9-0.4,1.9-0.9,2.9-1.4c2.9-1.2,5.7,0.2,6.5,3.1c1.4,4.8-3.7,14.7-8.9,17c-1.1-6.1,6.6-9.7,4.4-16.4c-2.6,2.3-4.9,4.5-7.5,6.8
			c-2.3-7.6,0.6-15.3-2.9-22c-0.5,0-1-0.1-1.5-0.1c-0.7,4.8-1.5,9.6-2.2,14.4c-0.5,0.1-1,0.2-1.6,0.3c-1.8-3.1-3.5-6.1-5.5-9.5
			c1.3,10.9,1.3,10.9-0.5,12.5c-2-1.9-4-3.7-5.9-5.6c-0.3,0.3-0.7,0.6-1,0.9C1013.3,1160.1,1016.9,1166.8,1020.9,1174.1z
			 M1006.6,1316c8.4-3.2,15.9-6.1,23.3-8.7c7.6-2.7,15.2-5.2,22.1-7.6c-0.5-6.8-1.2-12.5-1.3-18.2c-0.2-10.3,0-20.7,0-31
			c0-1.5-0.2-3.1-0.4-5.7c-4.5,6.7-9.2,10.9-16.8,10.4c-7.6-0.5-11.8-5.7-15.7-11.5c-4.3,6-8.2,11.4-11.9,16.9
			c-0.7,1-0.9,2.5-0.8,3.8C1005.5,1281.4,1006.1,1298.5,1006.6,1316z M1082.6,1244.9c5.3,3.5,10.1,6.7,14.9,9.8
			c0.7-0.6,1.2-0.8,1.4-1.2c1.6-2.9,3.2-5.8,4.8-8.7c4.3-7.7,6.2-8,12.5-2.1c0.2,0.2,0.6,0.2,1.1,0.3c3.9-4.7,5.7-11.7,13.4-12.6
			c-1.4-4.2-2.7-7.9-4.4-12.6c-0.9,4.8-3.6,5.9-6.9,5.3c-3-0.6-5.9-1.9-8.8-2.9c-5.4-1.9-5.9-1.7-8.4,3.5c-3.8,7.8-6.8,8.6-13.8,3.6
			c-0.4-0.3-0.8-0.6-1.2-0.9c-6-4.6-6.6-4.5-11,1.3c-0.9,1.2-2,2.2-2.8,3.4c-3.3,4.8-7.5,5.9-12.7,2.2c-0.5,3.9-0.9,7.1-1.2,10.4
			c-0.3,3.1-0.5,6.3-0.8,10.2c5-4.1,5-4.1,10.2-1.7c0.9,0.4,1.8,0.8,2.7,1.2c2.7,1.4,4.6,0.7,6.2-2
			C1079.1,1249.1,1080.9,1247.1,1082.6,1244.9z M1088.5,1181.2c12.2,0.3,20.6-10.9,20.2-20.2c-0.1-2.4,0.6-4.9,0.7-7.4
			c0-2.9,0.1-6-0.5-8.8c-1.2-5.6-4.8-7.9-10.5-7.4c-2.3,0.2-4.6-0.2-6.9,0.1c-5.4,0.5-10.9,0.7-16.2,2.1c-2.2,0.6-5.1,3.7-5.2,5.7
			c-0.2,7.6-0.1,15.3,1.2,22.7C1072.8,1176.7,1079.5,1181.2,1088.5,1181.2z M1085.6,1259.4c-1.6,3.4-2.6,6-3.9,8.4
			c-1.9,3.7-3.8,4.4-7.6,2.8c-2.5-1.1-5-2.4-7.2-3.4c-5.3,5.8-10.3,11.3-5.4,19.3c0.2-0.2,0.8-0.7,1-1.2c1.7-3.4,3.9-4.2,7.1-1.9
			c0.7,0.5,1.5,0.6,2.2,1.1c3.4,2.1,6.1,1.8,8.6-1.6c1.2-1.7,2.9-3.2,4.5-4.7c4.2-4,4.9-4.1,10-1.4c3.9,2,6.7,1.6,9.8-1.5
			c2.1-2.1,4.1-4.4,6.2-6.5c3.2-3.2,4.5-3.3,8-0.4c3.9,3.3,4.2,3.2,7-1.1c1.2-1.8,2.4-4.1,4.1-4.8c1.8-0.8,4.3,0.1,7.1,0.3
			c-0.9-5.4-1.8-10.8-2.8-17c-2.1,2.7-3.6,4.4-4.9,6.3c-2.6,4-6.1,5.3-10.7,3.9c-2.3-0.7-5.1-2-6.8-1.2c-1.9,0.9-2.9,3.8-4.3,5.9
			c-0.5,0.7-0.9,1.4-1.2,2.2c-1.7,4.7-6.6,5-11,3.4c-2-0.7-3.6-2.3-5.3-3.6C1088.8,1261.9,1087.5,1260.9,1085.6,1259.4z
			 M1111.5,1194.1c-0.6,0.5-1.2,0.8-1.3,1.2c-2.4,6.2-4.8,7.6-11.1,5.8c-3.3-0.9-6.5-2.3-10.3-3.8c-1.1,1.9-2.6,4-3.9,6.3
			c-3.2,5.4-7.7,6.3-12.8,2.4c-1.4-1.1-2.7-2.2-4.5-3.6c-3.7,5.8-4.7,11.7-4.8,17.9c6.3,2.2,6.3,2.2,10.2-2.1c0.4-0.5,0.9-1,1.3-1.5
			c3.8-4.4,6.5-5.1,11.8-2.6c3.1,1.4,6,3.3,9.3,5.2c0.9-1.7,1.6-3.1,2.4-4.6c3.1-6.1,5.5-7,12-4.8c2.8,0.9,5.6,1.7,8.3,2.5
			C1122.3,1206,1120.6,1201.3,1111.5,1194.1z M1158,1220.6c-3.1,5.3-6.1,10.4-9,15.6c-0.4,0.8-0.4,2-0.2,2.9
			c1.3,6.5,2.6,13.1,4,19.9c5.2-1.8,10.8-3.8,15.7-5.5c-0.5-9.9-0.9-19.3-1.4-29C1164.6,1223.4,1161.8,1222.2,1158,1220.6z
			 M1077.1,1197.8c2.9-3.1,5.7-6.1,8.6-9.2c-4.1-1.2-8-2.4-11.9-3.6C1071.4,1190.3,1072,1192.5,1077.1,1197.8z M1094.4,1187.4
			c4.6,4.6,7.4,5.5,9.7,3.2c3.4-3.2,3.5-6.9,0.3-10.3C1101.1,1182.7,1097.8,1185,1094.4,1187.4z'
        />
        <path
          d='M1668.2,443.7c0.5,2.9,1.1,5.4,1.4,8c0.8,7.2,1.6,14.5,2.3,21.7c0.5,4.9,1.2,9.8,1.6,14.8c1,10.7,2,21.4,2.9,32.1
			c0.5,5.3,0.8,10.6,1.2,15.9c0.7,8.9,1.6,17.8,1.9,26.7c0.3,8,0.1,16-0.2,24c-0.1,1.4-1.5,3.2-2.8,4c-12.5,7.5-25.5,13.8-39.9,17.2
			c-11.9,2.8-23.6,6.4-35.3,9.7c-5.1,1.4-10.2,2.7-15.3,3.9c-4.7,1.2-7.2-0.3-9.5-4.8c-6.4-12.6-9.6-26.2-12-40
			c-1.9-10.7-4.1-21.3-5.7-32c-1.1-7.2-1.8-14.5-2.2-21.7c-0.4-7.1-0.2-14.2-0.1-21.3c0.1-6.4,3-10.7,9.2-13.4
			c10.3-4.5,20.3-9.8,30.6-14.4c12.7-5.7,25.5-10.9,38.2-16.5c7.1-3.1,14.1-6.6,21.2-9.6C1659.3,446.2,1663.3,445.3,1668.2,443.7z
			 M1641.4,462.4c-6.6,2.6-13.2,5.1-19.7,7.9c-3.2,1.4-6,3.8-9.2,4.8c-14.4,4.8-27,13.3-40.6,19.5c-4.7,2.2-6.3,5.8-6.2,10.7
			c0.1,6.3,0.1,12.7,0,19c-0.1,10.9,0.3,21.7,3,32.3c2.5,10.1,5,20.1,7.9,30.1c2.3,8.2,4.9,16.4,7.8,24.4c1.3,3.7,3.8,4.5,7.4,3.4
			c8-2.5,16.1-4.9,24.1-7.4c16.4-5.1,33-9.5,48.3-17.5c2.8-1.5,6.6-2.3,7.9-4.6c1.4-2.4,0.5-6.3,0.3-9.5c-0.6-9.8-1.3-19.5-2-29.3
			c-0.1-1-0.3-2-0.8-2.8c-4.4-7.4-8.6-14.9-13.4-22c-1.5-2.2-4.4-3.9-7.1-4.9c-6.7-2.4-13.7-2.4-20.6-0.7
			c-5.1,1.2-10.6,1.9-15.1,4.5c-10.2,5.9-17.9,14.6-21.7,25.8c-6.3,18.6-4.1,36.6,5.6,53.6c1.1,1.9,1.9,3.8,3,5.9
			c-5.4,1.9-8.8,0.1-11.1-3.5c-2.2-3.4-4.3-7.1-5.6-11c-4.2-12.3-6.2-25-4.5-36.6c-2.7-3.1-5.1-5-6.5-7.5
			c-4.7-8.4-6.6-17.6-6.1-27.3c0.3-6.3,4.4-10.1,10.7-9.5c3.4,0.3,6.7,1.2,10.3,1.9c1.5-3.4,2.8-6.8,4.4-10
			c1.7-3.4,3.6-6.8,5.3-10.1c3.7-6.9,3.7-6.9,6.6-8.4c-4.5,10.1-8.7,19.7-13,29.4c2.3,0.6,4.2,1,6.2,1.5c6.5,1.5,14.5-2.3,17.5-8.3
			c7.6-15.4,16.5-30.1,26.9-43.8c2.6,1.8,0.1,3.2-0.6,4.4c-4.8,8.1-10,15.9-14.7,24c-3.3,5.6-6.1,11.4-9.3,17.5
			c5.1-0.8,9.4-1.4,13.6-2c2.7-0.4,4.1-1.7,5.2-4.4c2.5-6.3,5.2-12.6,8.5-18.5c4.6-8.3,10.9-8.4,15.7-0.1c3,5.2,5.2,10.9,7.6,16.1
			c0.7-14-1.4-35.4-4.4-45C1655.4,456.3,1647.7,457.5,1641.4,462.4z M1650.8,491.9c-2.4,4.9-4.5,9.2-6.4,13
			c5.5,1.9,10.6,2.8,14.6,5.4c3.9,2.6,6.7,6.9,10,10.4c-0.3-5.8-0.6-11.4-0.9-17.1c-0.5,2.1-1,4-1.7,6.4
			C1658.5,506.1,1655.5,499.1,1650.8,491.9z M1580.7,544.3c4.2-7,7.8-13.1,11.6-19.5c-5.1-0.8-10.1-1.5-16-2.4
			C1577.8,530,1579.2,536.9,1580.7,544.3z'
        />
        <path
          d='M1374.1,320c-2.3,3.4-4.3,6.2-6.1,9.1c-1.8,3-3.3,6.1-5.2,9.1c-4.8,7.5-11.7,12-20.8,11.4c-7.7-0.6-13.2,2-17.2,8.5
			c-0.3,0.6-0.8,1.1-1.2,1.6c-7,9.2-16,13.4-27.5,9.7c-6.3-2-10.6,0.2-14.2,5.1c-3.5,4.7-6.9,9.4-10.6,13.9
			c-3.5,4.2-7.7,6.9-13.5,4.7c-5.2-2-10.2-9-10.3-14.2c-0.1-2.2,0.3-4.4,2.6-4.7c1.4-0.2,3.4,1,4.5,2.1c1.5,1.5,2.5,3.5,3.7,5.3
			c3.1,4.3,4.6,4.6,8.1,0.8c3.1-3.3,5.7-6.9,8.7-10.3c4.3-4.8,8-10.4,15-11.6c2.7-0.5,5.6-0.7,8.3-0.2c6.8,1.2,12.5-0.6,17.2-5.6
			c1.1-1.2,2.3-2.5,3.2-3.8c5.6-7.7,12.6-12.2,22.8-11.5c7.5,0.5,13.3-3.3,16.9-10.2c2.3-4.4,5-8.6,8-12.6c5.1-6.8,10-7.6,17.2-3.1
			c8.2,5.2,8.4,5.2,15-1.6c1-1.1,2.1-2.1,3.1-3.2c4.9-5.1,10.6-7.4,17.9-7.1c7.9,0.4,6.9,1.5,10.3-6.9c0.9-2.3,1.8-4.7,3-6.8
			c4.1-7.1,8.7-13.7,18.7-12c1.3-3.9,2.5-7.5,3.8-11c3.8-9.9,10-13.1,20-10c3.1,1,5.8,0.9,8.6-0.6c6.6-3.7,13.3-7.3,19.9-11.1
			c4.7-2.7,9.7-3.9,14.7-1.6c3.1,1.4,5.2,0.7,7.7-1.2c3.3-2.5,6.6-5.1,10.2-7.1c6.6-3.7,13.2-3.6,19.9,0.3c2.8,1.6,2.9,3.3,1.1,5
			c-2.5,2.3-5.4,4.3-8.3,6.2c-5.1,3.3-10.6,6.2-15.5,9.8c-6.1,4.4-12.2,6-18.9,2c-2.2-1.3-3.8-0.6-5.9,0.6
			c-7.2,4.1-14.5,8.1-21.8,11.9c-4.3,2.2-8.9,2.7-13.5,1.3c-3.6-1.1-5.5,0.1-6.7,3.6c-1.4,4.2-3.2,8.4-5,12.4
			c-1.9,4.2-5.3,6.4-10.1,5.6c-3.1-0.5-4.6,0.8-5.8,3.3c-1.5,3.3-3.2,6.6-4.8,9.8c-3.8,7.9-10.1,12.3-18.8,10.8
			c-7.9-1.3-13.1,1.7-18.3,7.1c-7.8,8.3-15.9,8.4-24.6,1.2C1377.8,322.7,1376.3,321.7,1374.1,320z'
        />
        <path
          d='M1485.3,941.9c-4.2-0.7-10.8,2-17.3,5.8c-4.1,2.4-8.8,3.7-13.1,5.8c-2.3,1.1-4.2,3-6.2,4.5c-0.2,0.2-0.4,0.5-0.5,0.8
			c-10.1,17.6-24.3,22.1-46.3,17.8c-1.6-0.3-3.2-0.8-4.8-1.2c-3.4-0.8-6.6-0.4-9.4,1.9c-2.2,1.8-4.5,3.5-6.4,5.5
			c-8,8.2-17.8,11.2-28.9,10.6c-3.5-0.2-7-0.4-10.5-0.5c-2.9-0.1-5.1,1.1-6.7,3.6c-2.3,3.5-4.5,7-7.1,10.2
			c-5.6,7.2-12.2,8.9-20.7,5.6c-2.6-1-5.3-2-7.9-2.8c-3.8-1.1-5-3.5-3.5-7.2c1.8-4.3,6.5-7.1,11.6-5c7.3,2.9,10.9,0,14.5-5.8
			c5.8-9.2,14-14.3,25.3-13.7c4.3,0.2,8.7,0,12.9-1c4.1-1,8.1-2.8,11.7-5c3.8-2.3,7-5.6,10.6-8.3c5.3-3.9,11.2-5.3,17.9-4.1
			c8.1,1.5,16.2,0.3,24.2-1.6c1.8-0.4,3.5-1.8,4.9-3.2c1.6-1.6,3-3.6,4.2-5.6c4.6-8.3,11.7-11.8,20.9-13.3c8.2-1.3,16.2-4.4,24.3-7
			c10.9-3.5,21.2-2.3,31.1,3.4c3.7,2.1,3.8,3.7-0.1,5.8C1503.3,941.6,1496.2,942.8,1485.3,941.9z'
        />
        <path
          d='M1625.1,1066c-5.6,6-12.1,9.7-19.4,12.2c-3.3,1.1-6.6,2.1-10,2.8c-3.4,0.7-5.6,2.7-7.2,5.6c-1.1,2-2.1,4.1-3.4,6.1
			c-5.6,8.2-13.3,11.8-23.2,10c-3.7-0.7-6.4-0.1-8.5,3.3c-1.2,2-2.7,3.8-4.2,5.6c-5.1,5.9-11.4,8.2-19.1,7.2
			c-4.8-0.6-9.6-0.7-14.4-1.3c-6.2-0.8-10.3,1.1-13.8,7c-6.8,11.6-23.8,12.5-32.5,2.3c-3.3-3.9-5.2-8.4-2.8-13.3
			c0.9-1.8,3-3.4,5-4.2c2.4-0.9,3.9,1.1,4.9,3.1c1.8,3.9,5.2,4.8,9.1,4.7c3.8-0.1,4.9-2.9,6-5.7c3.4-9.4,10.9-13,19.9-14.2
			c5.7-0.7,11.6-1,17.4-0.9c3.2,0,5.3-0.9,7.1-3.5c2.2-2.9,4.6-5.7,7.3-8.2c5.3-5.1,11.6-6.7,18.8-4.8c6.1,1.6,6.1,1.4,9.5-3.8
			c4.8-7.7,10.9-14.2,20.4-15.3c5.2-0.6,10.6-0.1,15.8,0.5c4.9,0.6,9.7,1.9,14.6,3C1623.2,1064.3,1623.9,1065.2,1625.1,1066z'
        />
        <path
          d='M1396.4,1154.4c-4.6,12.9-15.2,16.5-27,16.7c-18.6,0.2-15.8,1.1-23.7,12.6c-8.9,12.9-22.2,16.1-36.8,9
			c-2.5-1.2-4.9-2.6-8-4.3c-1.5,4.2-2.8,7.9-4.1,11.5c-2.1,5.6-4.2,11.2-8.6,15.5c-2.6,2.5-5.7,3.4-9.1,2.7c-4.1-0.8-5.8-4.9-3-7.3
			c5.1-4.5,6.6-10.6,8.6-16.5c1.3-3.9,2.5-7.9,4.1-11.7c3.8-8.8,10.6-11.9,19.7-9.3c4.1,1.2,8.2,2.6,12.3,3.9c4.6,1.5,8.1,0,11-3.6
			c1.7-2.1,3.2-4.3,4.8-6.4c6.1-8.2,14.3-12.1,24.4-12.2c4,0,8,0.2,12,0c4.8-0.3,8.7-1.9,9.8-7.3c0.4-1.9,1.3-3.7,2.3-5.5
			c4.1-7.7,11.1-10,18.8-6c3.5,1.8,6.9,4.1,10.1,6.3c2.7,1.9,5.4,2.9,8.8,2.6c4-0.3,4.7,0.9,3.1,4.7c-3.3,8.4-11.4,12-20.4,8.9
			C1402.4,1157.7,1399.7,1156,1396.4,1154.4z'
        />
        <path
          d='M1450,630.7c-2.2-1.4-5.9-3.2-8.7-5.7c-2.3-2.1-4.1-5-5.1-7.9c-0.7-2-0.4-5.2,0.9-6.9c4-5.2,8.2-10.6,13.2-14.8
			c13.8-11.5,28-22.4,42.2-33.5c6.4-5,13.1-4.8,20-0.9c2.3,1.3,4.8,2.3,7.2,3.3c4.8,2,5.6,4.5,2,8.2c-5.1,5.2-10.1,10.8-16,15
			c-15.1,10.5-28.2,23.3-41.1,36.3C1461,627.4,1456.8,630.1,1450,630.7z'
        />
        <path
          d='M1653.3,275.1c0.6,4.4-2.2,5.7-4.5,6.2c-8.6,1.8-12.1-5-16.4-11.4c-3,5.3-5.7,9.9-8.3,14.7c-2.8,5.1-6.4,9.5-11.9,11.5
			c-3.5,1.3-7.3,2.3-11,2.4c-4.3,0.1-7.3,1.6-9.6,5.1c-2,3-4,6.1-6.1,9.1c-2.3,3.4-5.5,5-9.7,5c-14.2,0-12.2-1.9-19.6,10.6
			c-6.8,11.3-17.7,14.8-30.1,10.1c-3.1-1.2-6-2.9-9.5-4.6c-1.6,2.8-3.1,5.6-4.8,8.4c-1.4,2.2-2.8,4.6-4.6,6.4
			c-3.3,3.4-8.4,3.3-11.7,0.3c-3.4-3.2-4.5-10-2-13.3c2.1-2.7,3.9-2.6,8.7,0.9c1.8-3,3.4-6.1,5.3-9c4.7-7.2,9-8.4,16.8-5
			c3,1.3,6.1,2.5,9.3,3.5c5,1.5,9.2,0,12.3-4.2c1.9-2.5,3.6-5.1,5.5-7.7c4.1-5.8,9.8-9,16.7-7.7c7.8,1.4,12.2-1.4,15.8-8
			c4-7.5,10.5-11.5,19.4-12c5.4-0.3,10-2.9,13.1-7.9c3-4.8,6.4-9.3,10.1-13.6c5.1-6,9.7-5.5,13.8,1.2c1.2,2,2.3,4,3.7,5.9
			C1646,274.9,1648.5,277.6,1653.3,275.1z'
        />
        <path
          d='M1292.6,1091.5c2.1-4.9,3.4-8.4,5-11.9c3.7-8.1,10.2-10.7,17.9-6.4c13.1,7.4,10.8,5.3,19.5-4.5c6.4-7.1,13.5-12.4,23.2-13
			c4.4-0.2,9,0.3,13.2,1.6c4.4,1.4,8.4,1.5,12.6,0.2c3.5-1,7-2,10.5-2.9c1.5-0.4,3.1-0.5,5-0.9c-0.1,1.6,0.1,2.7-0.3,3.5
			c-4.9,10.7-15.3,21.5-31.6,19.2c-2.5-0.3-5-0.5-7.4-1c-5.9-1.2-10.8,0.3-14.8,4.9c-2.6,3-5.3,5.9-8.2,8.7
			c-5.4,5.1-11.7,5.8-18.4,3c-3.3-1.4-6.5-3.2-10.1-5c-1.4,2.8-2.6,5.7-4.1,8.4c-5.7,10.6-15.7,11.7-23.2,2.5c-2.4-3-4-6.8-5.5-10.4
			c-1-2.3-0.3-5.1,2.1-5.9c1.6-0.6,4.3,0.1,5.8,1.2C1286.7,1085.1,1289.1,1088,1292.6,1091.5z'
        />
        <path
          d='M1329.9,513.3c-1.6,3.5-2.7,6.4-4.2,9.1c-2.7,4.8-6.7,6.5-10.7,4.8c-3.6-1.5-7.3-8.4-6.5-12.1c0.6-2.6,3-3.7,5.3-2
			c1.4,1,2.5,2.6,4.2,4.4c1-2.2,1.8-3.9,2.5-5.6c1-2.3,1.8-4.7,3-6.8c3.5-5.8,8.7-6.5,13.6-1.8c1.8,1.7,3.3,3.6,5.4,5.9
			c1.4-2.3,2.6-4.4,3.8-6.5c2.9-4.6,5.5-9.5,8.8-13.8c3.5-4.6,8.4-6.9,14.3-6.8c3.7,0.1,7.3,0.2,11,0.3c6.8,0.3,12.7-2,17.5-6.8
			c1.8-1.8,3.5-3.8,5.7-4.8c2.1-1,4.8-1.3,7-0.9c3,0.6,3.7,3,2.2,5.8c-5.7,11.2-13.9,19.5-26.6,22c-3.5,0.7-7.2,0.6-10.8,0.1
			c-9-1.2-10.4-0.7-14.8,7.1c-2.2,3.9-4.3,7.9-6.9,11.5c-6.7,9.4-14.3,9.3-21.3,0.1C1331.8,515.6,1331.1,514.7,1329.9,513.3z'
        />
        <path
          d='M1525.5,1015.2c-4.9-1.7-8.7,1.3-12.9,5.3c-10.5,10.1-23,14.5-37.4,9.3c-3.4-1.2-5.4-0.4-7.8,2.2c-6,6.6-14,7-20.7,1.3
			c-1.2-1.1-2.7-2.4-3-3.8c-0.5-1.7-0.6-4.2,0.4-5.5c0.9-1.2,3.8-2,5.1-1.5c5.4,2.2,9.2,1.2,12.9-3.4c3.7-4.6,8.9-6.2,14.8-4.5
			c1.9,0.5,3.9,0.9,5.9,1c7.5,0.7,14.2-0.8,18.8-7.6c0.8-1.2,1.8-2.4,2.7-3.6c7.6-9.2,15.4-11.3,26.6-6.9c4.9,1.9,9.6,4.4,14.3,6.7
			c2.3,1.2,1.6,2.9,0.4,4.5c-3.2,4-7.5,5.9-12.5,6.4C1530.9,1015.4,1528.7,1015.2,1525.5,1015.2z'
        />
        <path
          d='M1315.3,427.4c1.4-2.8,2.5-5.1,3.8-7.4c5-9.2,10.4-13.5,22.4-8.8c8.1,3.1,8.9,2.6,14.2-4.1c2.5-3.1,4.9-6.3,7.7-9.1
			c3.6-3.6,8.1-5.6,13.1-4.2c10,2.9,19-0.3,28-3.6c1.7-0.6,3.5-1.2,5.4-1.8c0.5,8.7-7.8,19-19.8,21.1c-4.6,0.8-9.6,0.3-14.2-0.5
			c-3.3-0.5-5.4-0.1-7.2,2.7c-1.6,2.5-3.5,4.8-5.5,7.1c-7.2,8.2-14.9,9.6-24.5,4.7c-8.8-4.6-9.1-4.4-13.2,4.7c-1.5,3.5-3,7-5,10.3
			c-3.5,5.8-9.6,5.6-12.9-0.3c-2.7-4.9-3.6-10.3-3-15.9c0.2-1.4,1.9-3.4,3.2-3.6c1.3-0.3,3.5,0.8,4.5,2
			C1313.6,422.4,1314.2,424.7,1315.3,427.4z'
        />
        <path
          d='M1643.1,970.6c1.2,9.5-5.7,18.5-14.9,19.7c-1,0.1-2,0.3-3,0.2c-6.4-0.6-12,1.3-17.1,5.3c-3.4,2.7-7.6,4-12.1,3.7
			c-5.7-0.3-10.7-4.2-11.5-9.2c-0.9-5.4,1.9-9.4,7.5-12c3.6-1.6,7-3.8,10-6.3c7-5.6,14.6-7.2,23.3-5
			C1631.2,968.5,1637.2,969.4,1643.1,970.6z'
        />
        <path
          d='M1637.7,899.5c-3.4,1.6-6.5,2.8-9.5,4.5c-4.6,2.6-9.2,5.3-13.4,8.4c-8.8,6.5-15.7,6.9-25.2,1c-0.3-0.2-0.6-0.3-0.9-0.5
			c-5.9-3.7-6-3.7-11.2,0.7c-4.9,4.1-10.3,6.9-16.8,5.6c-2.1-0.4-4.6-1.5-5.9-3.1c-1.1-1.4-1.6-4.2-1-5.9c0.5-1.4,3-3.1,4.5-3
			c5,0.5,8.6-1.9,12.2-4.7c6.9-5.2,14.4-6.7,22.3-2.9c4.8,2.3,8.7,1.5,12.7-2c3-2.5,6.5-4.6,10.1-6.1
			C1624,888.1,1633.2,891.3,1637.7,899.5z'
        />
        <path
          d='M1571.7,411.9c-1.1,0.9-2.2,1.9-3.4,2.6c-6.8,4-13.6,8.3-20.7,11.9c-3.7,1.9-8,3-12.1,4.2c-3.1,0.9-6.1,1.6-8,4.5
			c-0.5,0.7-1.1,1.2-1.7,1.9c-4.7,6-10.6,8.4-18,5.5c-0.8-0.3-1.6-0.5-2.4-0.7c-2,2.8-3.7,5.7-5.8,8.2c-5.1,5.8-11.2,6.1-17.1,1.1
			c-1.4-1.2-2.8-2.4-4-3.8c-0.8-1-1.9-2.9-1.5-3.6c0.8-1.4,2.5-3.1,3.9-3.1c2.3,0,4.9,0.8,7,1.9c2.8,1.4,4.7,1.1,6.1-1.7
			c0.4-0.9,1-1.7,1.5-2.6c4-6.8,7.3-8.4,14.8-6.4c2.9,0.8,4.9,0.3,6.6-1.7c4.8-5.7,10.8-8.8,18.1-10.3c3.8-0.8,7.3-3,10.7-5.1
			c8-4.9,16.5-4.7,25.2-4C1571.3,411.1,1571.5,411.5,1571.7,411.9z'
        />
        <path
          d='M1625.7,386.2c1.4-2.1,2.4-4,3.8-5.6c3.8-4.6,7.3-9.6,11.8-13.3c5.8-4.9,12.6-4.5,18.4-0.4c2.3,1.6,2.9,3.3,0.2,4.9
			c-8.1,4.6-13.4,11.7-18.6,19.2c-1.7,2.4-3.8,4.6-6.1,6.6c-5.3,4.5-11.5,4.5-16.6-0.3c-3-2.8-5.7-6.1-8.1-9.5
			c-0.9-1.2-1.2-3.7-0.5-5c0.7-1.3,3-2.4,4.4-2.3c2.2,0.2,4.4,1.3,6.4,2.3C1622.5,383.6,1623.8,384.8,1625.7,386.2z'
        />
        <path
          d='M1520.8,508.9c-6.4,10.1-14.4,15.8-25.8,15.6c-1.2,0-2.4,0-3.4-0.5c-4.2-1.8-7.3-0.7-10,3c-5.3,7.3-12,9.2-19.5,2.8
			c-0.7-0.6-1.7-1-3.2-1.8c-2.1,3.1-4.1,6.1-6.2,8.9c-0.8,1-2.2,1.6-3.3,2.3c-0.6-1.2-1.7-2.4-1.8-3.7c-0.4-5.1,4.4-12.4,9.1-14.4
			c3.7-1.5,7-0.3,9.8,1.7c3.3,2.3,5.7,2,8-1.1c0.8-1.1,1.8-1.9,2.6-3c3.7-5,8.1-6.6,14.3-4.9c4.9,1.3,10.2,0.6,15.1-1.7
			c3.1-1.5,6.4-2.6,9.7-3.6C1517.4,508.3,1518.9,508.8,1520.8,508.9z'
        />
        <path
          d='M1645.1,200.8c-3,4-7.1,5.4-12.5,3.1c-3.8-1.7-5.4-0.6-7.6,2.4c-2.8,3.8-6.2,7.3-9.9,10.4c-1.7,1.5-4.6,2.2-6.9,2.2
			c-1.6,0-4.1-1.7-4.4-3.1c-0.4-1.7,0.4-4.9,1.7-5.5c6.5-3.1,9.4-9.1,13-14.7c4.7-7.3,10.4-8,17.1-2.5
			C1638.7,195.7,1641.9,198.3,1645.1,200.8z'
        />
        <path
          d='M1663.6,625.1c-2.6,0.8-5.1,1.5-7.7,2.2c-7.9,2.2-15.8,4.4-23.8,6.6c-1.4,0.4-2.8,1.2-4.2,1.4c-6.4,0.9-12.7,1.8-18.8,4.4
			c-1.1,0.5-3-1-4.6-1.6c0.7-1.3,1-3.1,2.1-3.9c4.9-3.3,10.1-5.7,16.3-5.2c1.6,0.1,3.3-0.4,4.8-0.9c5.6-1.9,11.2-4.1,16.8-5.9
			c6.4-2.1,12.7-1.5,18.7,1.4C1663.4,624.3,1663.5,624.7,1663.6,625.1z'
        />
        <path
          d='M299.9,982.1c6.4-0.2,14.3,7.5,14.2,13.7c-0.1,2.9-2.1,4.4-4.6,3c-2-1.1-3.7-2.8-5.3-4.4c-3.8-4-5.9-4.5-11-2.2
			c-5.4,2.5-10.8,4.9-16.2,7.4c-1.9,0.8-4.3,1.5-5.1-0.9c-0.5-1.4,0.4-3.8,1.5-5.1C276.9,989.7,294.6,982.3,299.9,982.1z'
        />
        <path
          d='M292.7,970.5c-3.3,0.1-6.2-2.2-6.3-5c-0.2-4.1,2.9-8.2,6.2-8.3c3.6-0.1,6.5,2.8,6.5,6.6
			C299.2,967.4,296.4,970.4,292.7,970.5z'
        />
        <path
          d='M190.8,962.5c2.3,1.1,4.9,2,6.8,3.5c1,0.8,1.7,3.1,1.3,4.3c-0.4,1.2-2.2,2.5-3.6,2.8c-3.6,0.9-8.3-2.6-9.1-6.1
			C185.8,964.5,187.3,963.1,190.8,962.5z'
        />
        <path d='M304.3,941.2c0,3-2.6,5.8-5.5,5.9c-2.9,0.1-5.3-2.3-5.2-5.3c0.1-3.6,2.2-6.1,5.1-6.2C301.9,935.6,304.4,938,304.3,941.2z' />
        <path
          d='M265.3,932.4c2.2,0.9,4.6,1.4,6.1,2.8c0.6,0.5-0.4,3.9-1.6,4.8c-1.2,0.8-3.9,0.8-5.2-0.1
			C262,938.1,263.3,935.6,265.3,932.4z'
        />
        <path
          d='M237.1,1000.4c0.1-3.6,3.1-6.3,6.8-6.3c4.4,0.1,8.2,2.6,8.2,5.5c0,3.2-4.8,7.9-7.9,7.6
			C240.3,1006.9,236.9,1003.7,237.1,1000.4z'
        />
        <path
          d='M243.2,1032.4c-3.9,0-6.8-2.3-6.8-5.6c0-4.2,2.7-9.1,5.1-9.2c4.5-0.2,7.5,2.8,7.6,7.8
			C249.2,1029.6,246.9,1032.4,243.2,1032.4z'
        />
        <path
          d='M229.4,1020c0,4.6-2.3,7.5-5.5,7.8c-3.4,0.2-4.4-2.3-4.8-5.1c-0.2-1.1-0.8-2.4-0.6-3.4c0.5-2.1,1.3-5.6,2.4-5.8
			c2-0.3,4.6,1,6.4,2.4C228.6,1017.1,229,1019.3,229.4,1020z'
        />
        <path d='M218.1,997.6c0-4.8,2.2-7.7,6.1-7.6c2.8,0,5.1,2.8,5,6.1c-0.1,3.3-3.4,6.3-7,6.2C219.5,1002.1,217.9,1000.7,218.1,997.6z' />
        <path
          d='M1125.4,1105.3c0.5-3.4,0.8-6.9,1.6-10.3c1.9-7.4,8.8-12.7,16.4-13c8.4-0.3,16,4.7,19,12.7c4.9,13-5.1,28.8-19,28.7
			C1133.1,1123.3,1125.5,1118.4,1125.4,1105.3z M1144.7,1094c-5.1,0-8.6,4-8.8,10c-0.1,5,3.3,8.9,7.9,9c5.3,0.1,10.5-4.5,10.4-9.3
			C1154.2,1098.9,1149.4,1094,1144.7,1094z'
        />
        <path d='M1093.4,1162.7c-2.7,6.5-8.2,7.5-12.8,2.4C1084.6,1161.4,1089.7,1165.7,1093.4,1162.7z' />
        <path
          d='M1100.1,1151.9c-1.9,1.5-3,3.1-4.1,3.1c-3.2,0.1-3.4-2.4-3.4-4.9c0-1.9,0.3-4.5,2.5-3.8
			C1097,1146.9,1098.1,1149.5,1100.1,1151.9z'
        />
        <path d='M1079.6,1156.6c-4.1-2.8-4.7-6.1-1.9-9.4C1081.2,1150.2,1081.5,1151.8,1079.6,1156.6z' />
        <path
          d='M1654.3,583c-3,3.1-6.8,3.7-11,3.2c-0.1,2,0.2,4.1-0.4,5.9c-0.3,0.9-2.4,1.1-3,1.3c-1.1-3-1.4-6.5-3.1-7.7
			c-2-1.4-4.3-3.6-7.9-2.5c-3.6,1.1-6.9-0.6-8.7-4.2c-1.8-3.7-1.1-7.3,1.1-10.8c2.2-3.3,5.3-5,9.2-4.5c5.6,0.7,8,3.6,8.4,9.5
			c0.4,5.9,2.3,7.8,8.3,7.9c2.2,0.1,4.5,0,6.7,0C1654.1,581.9,1654.2,582.5,1654.3,583z'
        />
        <path
          d='M1603.3,569.2c-2.4,1.7-3.6,3.1-5,3.3c-2.4,0.3-4.3-1.4-3.8-3.9c0.3-1.3,2.3-3.1,3.3-3
			C1599.4,565.7,1600.7,567.4,1603.3,569.2z'
        />
        <path d='M1655.5,555.4c-4.1-2.3-5.4-4.7-4.1-7.1c1-1.9,2.7-2.8,4.4-1.5C1659.4,549.5,1656.4,552.2,1655.5,555.4z' />
      </g>
    </g>
  </svg>
)
