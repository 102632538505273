import React from 'react'

const LeftEye = () => {
  return (
    <g className='left-eye'>
      <path
        d='M403.1,151.4c-7.8-31.6-25.2-55.3-48.4-75.6c-18-15.8-41-22.3-64-26.2c-36.9-6.2-72.9,0.7-104.9,19.1
	c-41.2,23.7-65.2,61-73.6,107.9c-1.6,8.9-3.5,18.1-2.8,27c2.2,31.1,10.3,60.7,27.4,87.2c26,40.3,61.4,64.6,113,63.1
	c0.1,0,1.9,0.4,3.6,0.2c16-1.7,33.1-0.7,47.9-6c32-11.3,59.7-29.8,77.9-59.8c9.6-15.8,21.2-31.4,22.7-50
	C404.3,209.6,410.4,181.2,403.1,151.4z M379.2,235.9c-3.3,15.5-15.5,34.8-25.8,47.2c-15.4,18.5-31.3,26.7-55.5,42.9
	c-16.1,10.7-30.3,10.3-44,10.7c-2.6,0.1-5.1,0-5.2,0c-37.7-3.7-72.7-18.6-94.9-54.1c-14.6-23.3-13.5-44.7-15.4-72.2
	c-0.5-7.8-1-18.5,0.3-26.4c7.1-41.3,14.2-74.3,59-95.9c28.6-13.8,56.1-23.5,87.6-18.1c19.6,3.4,39.3,9.2,54.6,23.1
	c19.9,18,38.6,29.2,43,63.7C387.6,187,384.5,211,379.2,235.9z'
      />

      <path
        d='M128,160.5c0.9,0,1.7,0,2.6,0c1.2,0,2.2-0.4,3.3-0.5c1-0.1,1.9-0.4,2.9-0.4c0.9-0.1,1.8,0,2.7,0c2.1,0,4.5-0.3,6.5,0
		c1.1,0.2,2.1,0.5,3.2,0.8c1.8,0.5,3.4,0.4,5.2,0.4c4.4,0,9.3-0.6,13.5,0.3c5.1,1.1,10.7,0.5,15.8,0.5c8.1,0,16.5,0.9,24.5,2.2
		c5.2,0.8,10.5,1.2,15.7,1.7c3.1,0.3,6.2,0.5,9.3,0.5c3.6,0,7.3-0.2,11,0.1c6.4,0.5,12.9,0.5,19.3,1.1c8.7,0.8,17.7-0.3,26.4,0.1
		c7,0.3,13.9,0.8,21,0.8c7,0,14,0,21,0c4.3,0,8.7-0.4,12.9,0.3c2.2,0.4,4.7,0.1,6.9,0.1c2.6,0,5.1,0,7.7,0c3.1,0,6.1-0.1,9.2,0
		c1.8,0,3.4,0.8,5.2,0.9c2,0.1,4,0.4,6.1,0.4c1.2,0,2.6,0.2,3.8-0.1c0.9-0.2,1.7-0.3,2.7-0.3c2.2,0,4.4,0,6.5,0l-1.3,25.6
		c-3.4,0-6.7,0-10.1,0c-1.3,0-2.5-0.4-3.8-0.5c-1.3-0.1-2.6,0-3.9,0c-3.7,0-7.5,0-11.2,0c-2.6,0-4.9-0.3-7.4-0.4
		c-4.5-0.1-8.9-1.2-13.4-1.3c-2.8-0.1-5.4-0.9-8.2-0.9c-3.9,0-7.9,0.2-11.8-0.1c-3-0.2-5.9-0.4-8.9-0.3c-1.5,0-2.6-0.3-4-0.5
		c-2.5-0.3-5.3,0-7.8,0c-4.9,0-9.8,0-14.7,0c-4,0-8,0-12,0c-2.1,0-4.3,0.1-6.4,0c-1.5-0.1-2.9-0.5-4.4-0.5c-2.9,0-5.8,0-8.7,0
		c-6.6,0-12.9,1-19.5,1.6c-1.4,0.1-2.7,0.5-4.1,0.6c-2.2,0.2-5.4-0.5-7.4-0.8c-4.6-0.7-9.1-1.5-13.7-2.2c-7.1-1.1-14.2-2-21.3-2.6
		c-2.9-0.2-5.7-0.6-8.6-0.4c-2.1,0.1-4.3,0.6-6.4,0.8c-4.7,0.5-9.4,0.6-14.1,0.8c-5.6,0.2-11.2,0.1-16.8,0.1c-3.9,0-7.8,0-11.7,0
		c-5,0-9.9,0-14.9,0L128,160.5z'
      />

      <path
        className='eye-ball'
        d='M168.3,183.8c-0.2,13.9,1,27.8,3.9,40.9c3.2,14.7,8.2,28.3,13.5,41.6c2.8,7,5.6,14,9.3,19.8c8.3,13,19.9,19,31,24.7
		c8.5,4.3,17.1,8.6,26,8.2c10.5-0.4,20.5-7.3,29.4-16c16.3-15.8,30.2-38.1,38.2-65.3c4.5-15.3,7-32.2,7.1-49.1L168.3,183.8z'
      />
    </g>
  )
}

const RightEye = () => {
  return (
    <g className='right-eye'>
      <path
        d='M699.5,169.1c0.9-0.1,1.7-0.1,2.6-0.2c1.2-0.1,2.2-0.5,3.3-0.7c1-0.1,1.9-0.5,2.9-0.6c0.9-0.1,1.8-0.1,2.7-0.2
		c2.1-0.1,4.5-0.6,6.5-0.4c1.1,0.1,2.2,0.4,3.2,0.6c1.8,0.4,3.4,0.2,5.2,0.1c4.4-0.3,9.2-1.2,13.5-0.6c5.1,0.7,10.7-0.2,15.8-0.6
		c8.1-0.6,16.5-0.2,24.6,0.5c5.2,0.5,10.5,0.5,15.8,0.6c3.1,0.1,6.2,0.1,9.3-0.1c3.6-0.3,7.3-0.7,10.9-0.6
		c6.4,0.1,12.9-0.3,19.3-0.2c8.8,0.2,17.6-1.4,26.4-1.7c7-0.2,14-0.1,21-0.6c7-0.5,13.9-0.9,20.9-1.4c4.3-0.3,8.7-0.9,12.9-0.5
		c2.3,0.2,4.7-0.2,6.9-0.4c2.6-0.2,5.1-0.3,7.7-0.5c3-0.2,6.1-0.5,9.1-0.6c1.8-0.1,3.5,0.6,5.3,0.5c2-0.1,4,0.2,6.1,0
		c1.2-0.1,2.6,0,3.8-0.4c0.9-0.3,1.6-0.5,2.6-0.5c2.2-0.1,4.4-0.3,6.5-0.4L965,186c-3.4,0.2-6.7,0.4-10.1,0.7
		c-1.3,0.1-2.5-0.2-3.8-0.2c-1.3,0-2.6,0.2-3.9,0.3c-3.7,0.3-7.5,0.5-11.2,0.8c-2.6,0.2-4.9,0-7.4,0.1c-4.5,0.2-9-0.6-13.5-0.4
		c-2.8,0.1-5.4-0.5-8.2-0.3c-3.9,0.3-7.9,0.7-11.8,0.7c-3,0-5.9,0-8.9,0.3c-1.5,0.1-2.6-0.1-4-0.2c-2.6-0.1-5.3,0.4-7.8,0.6
		c-4.9,0.3-9.8,0.7-14.6,1c-4,0.3-8,0.5-12,0.8c-2.1,0.1-4.3,0.4-6.4,0.5c-1.5,0-2.9-0.3-4.4-0.2c-2.9,0.2-5.8,0.4-8.6,0.6
		c-6.6,0.4-12.8,1.9-19.3,3c-1.4,0.2-2.7,0.6-4.1,0.9c-2.2,0.4-5.5-0.2-7.5-0.3c-4.6-0.4-9.2-0.9-13.8-1.2
		c-7.1-0.6-14.3-1.1-21.4-1.2c-2.9,0-5.7-0.3-8.7,0.1c-2.1,0.3-4.2,0.9-6.3,1.2c-4.6,0.9-9.3,1.2-14,1.7c-5.6,0.6-11.1,0.8-16.7,1.2
		c-3.9,0.3-7.8,0.5-11.7,0.8c-4.9,0.3-9.9,0.7-14.8,1L699.5,169.1z'
      />

      <path
        d='M968.2,151.4c-7.8-31.6-25.2-55.3-48.4-75.6c-18-15.8-41-22.3-64-26.2C819,43.4,783,50.3,750.9,68.7
	c-41.2,23.7-65.2,61-73.6,107.9c-1.6,8.9-3.5,18.1-2.8,27c2.2,31.1,10.3,60.7,27.4,87.2c26,40.3,61.4,64.6,113,63.1
	c0.1,0,1.9,0.4,3.6,0.2c16-1.7,33.1-0.7,47.9-6c32-11.3,59.7-29.8,77.9-59.8c9.6-15.8,21.2-31.4,22.7-50
	C969.5,209.6,975.6,181.2,968.2,151.4z M944.4,235.9c-3.3,15.5-15.5,34.8-25.8,47.2c-15.4,18.5-31.3,26.7-55.5,42.9
	c-16.1,10.7-30.3,10.3-44,10.7c-2.6,0.1-5.1,0-5.2,0c-37.7-3.7-72.7-18.6-94.9-54.1c-14.6-23.3-13.5-44.7-15.4-72.2
	c-0.5-7.8-1-18.5,0.3-26.4c7.1-41.3,14.2-74.3,59-95.9c28.6-13.8,56.1-23.5,87.6-18.1c19.6,3.4,39.3,9.2,54.6,23.1
	c19.9,18,38.6,29.2,43,63.7C952.7,187,949.7,211,944.4,235.9z'
      />

      <path
        className='eye-ball'
        d='M747.4,179.8c-0.2,13.9,1,27.8,3.9,40.9c3.2,14.7,8.2,28.3,13.5,41.6c2.8,7,5.6,14,9.3,19.8c8.3,13,19.9,19,31,24.7
		c8.5,4.3,17.1,8.6,26,8.2c10.5-0.4,20.5-7.3,29.4-16c16.3-15.8,30.2-38.1,38.2-65.3c4.5-15.3,7-32.2,7.1-49.1L747.4,179.8z'
      />
    </g>
  )
}

export default ({ ...props }) => {
  return (
    <svg x='0px' y='0px' viewBox='0 0 1080 442.1' {...props}>
      <LeftEye />
      <RightEye />
    </svg>
  )
}
