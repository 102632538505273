import React from 'react'

const VerticalWorkshops = ({ ...props }) => {
  return (
    <svg
      width='33'
      height='188'
      viewBox='0 0 33 188'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M32.5 30.52L14.895 25.2L32.5 19.915V15.715L14.895 10.43L32.5 5.11001V0.490005L8.00002 8.295V12.495L24.73 17.815L8.00002 23.17V27.335L32.5 35.14V30.52Z'
        fill='#FFF4CC'
      />
      <path
        d='M26.2 44.3371C26.2 39.0521 22.28 35.2021 16.995 35.2021C11.71 35.2021 7.79002 39.0521 7.79002 44.3371C7.79002 49.6221 11.71 53.4721 16.995 53.4721C22.28 53.4721 26.2 49.6221 26.2 44.3371ZM16.995 39.4371C20.18 39.4371 22.42 41.3971 22.42 44.3371C22.42 47.3121 20.18 49.2721 16.995 49.2721C13.81 49.2721 11.605 47.3121 11.605 44.3371C11.605 41.3971 13.81 39.4371 16.995 39.4371Z'
        fill='#FFF4CC'
      />
      <path
        d='M25.78 57.17H8.00002V61.405H17.31C20.495 61.405 22.105 63.225 22.105 65.71C22.105 66.375 21.965 67.145 21.685 67.88L25.64 68.545C25.885 67.705 26.025 66.865 26.025 66.095C26.025 63.96 25.08 62.105 22.98 61.3H25.78V57.17Z'
        fill='#FFF4CC'
      />
      <path
        d='M32.5 71.423H8.00002L8.00002 75.658H15.735L8.00002 83.743V89.833L17.45 79.823L25.78 88.713L25.78 82.938L18.64 75.658H32.5V71.423Z'
        fill='#FFF4CC'
      />
      <path
        d='M20.915 103.088L23.68 105.293C25.36 103.613 26.2 101.128 26.2 98.7126C26.2 95.1776 24.38 92.0276 20.6 92.0276C16.435 92.0276 15.7 95.9126 15.14 98.7826C14.72 100.953 14.335 102.073 13.145 102.073C12.025 102.073 11.465 100.953 11.465 99.4126C11.465 97.4526 12.27 94.8976 14.09 93.3926L11.15 91.0476C8.98002 93.0426 7.79002 96.0526 7.79002 99.0276C7.79002 103.123 9.99502 106.273 13.635 106.273C17.485 106.273 18.185 102.703 18.745 99.8676C19.2 97.4526 19.515 96.1926 20.81 96.1926C21.965 96.1926 22.63 97.2076 22.63 98.7476C22.63 100.113 22.105 101.898 20.915 103.088Z'
        fill='#FFF4CC'
      />
      <path
        d='M32.5 109.807H8.00002V114.042H17.555C20.775 114.042 22.455 116.002 22.455 118.347C22.455 120.447 21.16 121.777 18.745 121.777H8.00002V126.012H19.69C23.96 126.012 26.2 123.387 26.2 119.677C26.2 116.807 24.94 115.092 23.19 114.042H32.5V109.807Z'
        fill='#FFF4CC'
      />
      <path
        d='M26.2 138.844C26.2 133.559 22.28 129.709 16.995 129.709C11.71 129.709 7.79002 133.559 7.79002 138.844C7.79002 144.129 11.71 147.979 16.995 147.979C22.28 147.979 26.2 144.129 26.2 138.844ZM16.995 133.944C20.18 133.944 22.42 135.904 22.42 138.844C22.42 141.819 20.18 143.779 16.995 143.779C13.81 143.779 11.605 141.819 11.605 138.844C11.605 135.904 13.81 133.944 16.995 133.944Z'
        fill='#FFF4CC'
      />
      <path
        d='M26.2 161.757C26.2 158.992 25.045 156.787 23.225 155.807H25.78V151.677H0.265015L0.265015 155.912H10.695C9.01502 157.067 8.00001 159.167 8.00001 161.757C8.00001 167.112 12.2 170.157 17.065 170.157C21.93 170.157 26.2 167.182 26.2 161.757ZM17.1 155.877C20.215 155.877 22.455 157.942 22.455 160.987C22.455 164.172 20.005 165.957 17.1 165.957C13.985 165.957 11.745 163.962 11.745 160.987C11.745 157.942 13.985 155.877 17.1 155.877Z'
        fill='#FFF4CC'
      />
      <path
        d='M20.915 184.674L23.68 186.88C25.36 185.2 26.2 182.714 26.2 180.299C26.2 176.764 24.38 173.615 20.6 173.615C16.435 173.615 15.7 177.499 15.14 180.369C14.72 182.539 14.335 183.66 13.145 183.66C12.025 183.66 11.465 182.539 11.465 180.999C11.465 179.039 12.27 176.484 14.09 174.979L11.15 172.634C8.98001 174.629 7.79001 177.64 7.79001 180.615C7.79001 184.71 9.99501 187.859 13.635 187.859C17.485 187.859 18.185 184.289 18.745 181.454C19.2 179.039 19.515 177.779 20.81 177.779C21.965 177.779 22.63 178.795 22.63 180.335C22.63 181.7 22.105 183.485 20.915 184.674Z'
        fill='#FFF4CC'
      />
    </svg>
  )
}

export default VerticalWorkshops
