import React from 'react'

export default ({ ...props }) => (
  <svg
    width='35'
    height='112'
    viewBox='0 0 35 112'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14.325 17.625C13.2794 17.6438 12.241 17.4481 11.2739 17.0499C10.3069 16.6517 9.4318 16.0594 8.70268 15.3097C7.97356 14.5599 7.40592 13.6686 7.0348 12.6909C6.66368 11.7131 6.49698 10.6697 6.54499 9.62497L6.54499 5.35501L0.544994 5.35501L0.544994 0.964995L25.645 0.964996L25.645 5.31497L21.575 5.31497L21.575 10.045C21.615 15.205 18.045 17.625 14.325 17.625ZM10.505 9.42502C10.4648 9.92973 10.5324 10.4372 10.7033 10.9138C10.8742 11.3904 11.1446 11.8252 11.4964 12.1893C11.8482 12.5534 12.2734 12.8385 12.7439 13.0257C13.2143 13.2129 13.7192 13.2979 14.225 13.275C14.6941 13.2913 15.1612 13.2074 15.5953 13.0288C16.0293 12.8503 16.4203 12.5812 16.7421 12.2395C17.0639 11.8979 17.3091 11.4915 17.4613 11.0475C17.6136 10.6036 17.6694 10.1322 17.625 9.66501L17.625 5.32498L10.495 5.32498L10.505 9.42502Z'
      fill='#FFF4CC'
    />
    <path
      d='M11.035 32.765L0.585002 32.765L0.585002 28.425L11.035 28.425L25.125 19.235L25.125 24.495L14.955 30.595L25.125 36.655L25.125 41.965L11.035 32.765Z'
      fill='#FFF4CC'
    />
    <path
      d='M25.125 49.275L14.435 49.275L25.125 59.335L25.125 65.255L13.345 53.795L0.584998 66.175L0.584998 60.105L11.875 49.275L0.584999 49.275L0.584999 44.965L25.125 44.965L25.125 49.275Z'
      fill='#FFF4CC'
    />
    <path
      d='M0.0250012 74.905C0.0250014 70.905 2.30499 67.615 6.51499 67.295L7.245 71.505C6.82514 71.4926 6.40739 71.5686 6.01875 71.7279C5.6301 71.8873 5.27924 72.1265 4.9889 72.43C4.69856 72.7336 4.47519 73.0947 4.33326 73.49C4.19133 73.8854 4.13403 74.3061 4.16502 74.725C4.16502 76.515 5.245 77.845 7.495 77.845L25.125 77.845L25.125 82.195L7.87501 82.195C2.44501 82.195 0.025001 78.965 0.0250012 74.905Z'
      fill='#FFF4CC'
    />
    <path
      d='M12.855 111.285C5.53499 111.285 0.0250007 105.955 0.025001 98.775C0.0250013 91.595 5.53499 86.255 12.855 86.255C20.175 86.255 25.685 91.585 25.685 98.775C25.685 105.965 20.185 111.285 12.855 111.285ZM4.16502 98.775C4.16502 103.505 7.84499 106.865 12.855 106.865C17.865 106.865 21.545 103.505 21.545 98.775C21.545 94.045 17.865 90.675 12.855 90.675C7.84499 90.675 4.16502 94.075 4.16502 98.775ZM34.275 105.115L30.595 106.165L27.825 96.425L31.115 95.375L34.275 105.115Z'
      fill='#FFF4CC'
    />
  </svg>
)
