import React from 'react'

export default ({ ...props }) => (
  <svg
    width='91'
    height='91'
    viewBox='0 0 91 91'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g clipPath='url(#clip0)'>
      <path
        d='M37.5 90.4C34.5 89.8 31.7 88.4 28.9 87.2C24.9 85.4 20.9 83.5 17.3 81C13.6 78.4 10.3 75.1 7.70005 71.4C1.50005 62.5 -0.499954 51.2 0.100046 40.4C0.300046 37.5 0.600046 34.5 1.70005 31.8C2.40005 29.9 3.40005 28.2 4.40005 26.5C6.90005 22.2 9.60005 17.9 12.9 14.2C18.9 7.50001 27.2 2.80001 36 1.20001C39.4 0.600007 42.9 0.400007 46.3 0.200007C51 -0.0999927 55.9 -0.299993 60.3 1.30001C62.9 2.20001 65.2 3.80001 67.5 5.30001C70.4 7.20001 73.4 9.20001 75.9 11.6C79.4 15 81.9 19.3 84.1 23.6C85.7 26.7 87.2001 29.9 88.3 33.3C92.1001 45.2 90.2 58.8 83.6 69.4C77 80 66 87.8 53.8 90.7C53.8 80.2 53.7 69.7 53.7 59.2C56.2 59.2 58.7 59.1 61.1 59.1C61.5 59.1 62 59.1 62.4 58.8C62.8 58.5 62.9 57.9 62.9 57.5C63.4 53.9 63.9 50.2 64.3 46.6C61.1 46.2 58 45.7 54.8 45.3C54.3 45.2 53.8 45.1 53.6 44.7C53.5 44.5 53.4 44.2 53.4 43.9C53.3 42.5 53.2 41.1 53.1 39.7C53 38 52.9 36.2 53.5 34.6C54.4 32.4 56.6 31 58.8 30.3C61.1 29.7 63.5 29.8 65.8 29.9C65.6001 26.6 65.5 23.3 65.3 20C65.3 19.6 65.2 19.1 65 18.8C64.7 18.3 64 18.2 63.5 18.2C59.5 17.7 55.5 17.1 51.6 17.6C47.6 18.1 43.7 19.7 41.1 22.7C38.6 25.7 37.8 29.7 37.3 33.5C36.9 37.2 36.7 40.8 36.8 44.5C33.4 44.5 30 44.6 26.6 44.6C26.8 48.8 26.9 53 27.1 57.2C30.5 56.7 34 56.6 37.4 56.9C36.5 67.9 36.5 79 37.5 90'
        fill='#080808'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='90.3' height='90.7' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
