import React from 'react'

export default ({ ...props }) => (
  <svg x='0px' y='0px' viewBox='0 0 1080 1920' {...props}>
    <g>
      <path
        d='M403.1,890.4c-7.8-31.6-25.2-55.3-48.4-75.6c-18-15.8-41-22.3-64-26.2c-36.9-6.2-72.9,0.7-104.9,19.1
	c-41.2,23.7-65.2,61-73.6,107.9c-1.6,8.9-3.5,18.1-2.8,27c2.2,31.1,10.3,60.7,27.4,87.2c26,40.3,61.4,64.6,113,63.1
	c0.1,0,1.9,0.4,3.6,0.2c16-1.7,33.1-0.7,47.9-6c32-11.3,59.7-29.8,77.9-59.8c9.6-15.8,21.2-31.4,22.7-50
	C404.3,948.5,410.4,920.1,403.1,890.4z M379.2,974.8c-3.3,15.5-15.5,34.8-25.8,47.2c-15.4,18.5-31.3,26.7-55.5,42.9
	c-16.1,10.7-30.3,10.3-44,10.7c-2.6,0.1-5.1,0-5.2,0c-37.7-3.7-72.7-18.6-94.9-54.1c-14.6-23.3-13.5-44.7-15.4-72.2
	c-0.5-7.8-1-18.5,0.3-26.4c7.1-41.3,14.2-74.3,59-95.9c28.6-13.8,56.1-23.5,87.6-18.1c19.6,3.4,39.3,9.2,54.6,23.1
	c19.9,18,38.6,29.2,43,63.7C387.6,926,384.5,949.9,379.2,974.8z'
      />

      <path
        d='M141,862.4c0.8,0.3,1.6,0.5,2.5,0.8c1.1,0.4,2.2,0.3,3.3,0.6c1,0.2,1.9,0.2,2.9,0.5c0.9,0.2,1.7,0.6,2.6,0.8
		c2,0.6,4.4,1,6.2,2c0.9,0.5,1.9,1.2,2.8,1.7c1.6,1,3.1,1.4,4.8,2c4.2,1.3,9,2.2,12.8,4.4c4.5,2.5,10.1,3.7,15,5.2
		c7.7,2.4,15.5,5.8,22.7,9.4c4.7,2.4,9.6,4.3,14.5,6.3c2.9,1.2,5.8,2.3,8.7,3.2c3.5,1.1,7.1,2,10.4,3.4c6,2.4,12.1,4.3,18.1,6.8
		c8.1,3.3,16.9,5,25.2,8c6.6,2.4,13,4.9,19.8,7c6.7,2.1,13.3,4.2,20,6.2c4.1,1.3,8.4,2.3,12.2,4.2c2,1,4.4,1.5,6.6,2.2
		c2.4,0.8,4.9,1.5,7.3,2.3c2.9,0.9,5.9,1.7,8.8,2.7c1.7,0.6,3,1.8,4.7,2.4c1.9,0.7,3.7,1.6,5.7,2.2c1.2,0.4,2.4,0.9,3.7,1.1
		c0.9,0.1,1.7,0.2,2.6,0.5c2.1,0.7,4.2,1.3,6.3,1.9l-8.9,24.1c-3.2-1-6.4-2-9.7-3c-1.3-0.4-2.3-1.1-3.5-1.6
		c-1.2-0.5-2.5-0.8-3.8-1.2c-3.6-1.1-7.2-2.2-10.7-3.3c-2.4-0.8-4.6-1.8-7-2.6c-4.2-1.5-8.2-3.8-12.4-5.2c-2.6-0.9-4.9-2.5-7.5-3.3
		c-3.8-1.2-7.6-2.2-11.3-3.6c-2.8-1.1-5.5-2.1-8.4-3c-1.4-0.4-2.4-1.1-3.7-1.6c-2.3-1-5-1.5-7.5-2.3c-4.7-1.5-9.3-2.9-14-4.4
		c-3.8-1.2-7.7-2.4-11.5-3.6c-2-0.6-4.1-1.2-6.1-1.9c-1.4-0.5-2.6-1.3-4.1-1.7c-2.8-0.8-5.5-1.7-8.3-2.6c-6.3-2-12.6-2.9-19.1-4.2
		c-1.4-0.3-2.7-0.4-4.1-0.7c-2.2-0.5-5-2.1-6.8-3c-4.2-2-8.3-4.1-12.4-6.2c-6.4-3.1-12.9-6.1-19.6-8.8c-2.7-1.1-5.3-2.3-8.1-3
		c-2.1-0.5-4.3-0.7-6.4-1.1c-4.6-0.9-9.1-2.2-13.7-3.5c-5.4-1.5-10.7-3.2-16-4.9c-3.7-1.2-7.5-2.3-11.2-3.5c-4.7-1.5-9.5-3-14.2-4.4
		L141,862.4z'
      />
      <path
        d='M129.6,967.4c0.9-0.1,1.7-0.2,2.6-0.2c1.2-0.1,2.2-0.6,3.3-0.7c1-0.1,1.9-0.5,2.9-0.7c0.9-0.1,1.8-0.1,2.7-0.2
		c2-0.2,4.5-0.7,6.5-0.5c1.1,0.1,2.2,0.4,3.2,0.6c1.8,0.3,3.4,0.2,5.2,0c4.3-0.4,9.2-1.3,13.5-0.8c5.1,0.6,10.7-0.4,15.8-0.8
		c8.1-0.7,16.5-0.5,24.6,0.1c5.3,0.4,10.5,0.3,15.8,0.3c3.1,0,6.2,0,9.3-0.3c3.6-0.3,7.3-0.8,10.9-0.8c6.4,0,12.9-0.6,19.3-0.5
		c8.8,0,17.6-1.7,26.3-2.1c7-0.3,13.9-0.4,21-0.9c7-0.6,13.9-1.2,20.9-1.8c4.3-0.4,8.7-1.1,12.9-0.8c2.3,0.2,4.7-0.3,6.9-0.5
		c2.6-0.2,5.1-0.4,7.7-0.7c3-0.3,6.1-0.6,9.1-0.8c1.8-0.1,3.5,0.5,5.3,0.4c2-0.1,4,0.1,6.1-0.1c1.2-0.1,2.6,0,3.8-0.4
		c0.9-0.3,1.6-0.5,2.6-0.6c2.2-0.2,4.3-0.4,6.5-0.6l0.9,25.6c-3.4,0.3-6.7,0.5-10.1,0.9c-1.3,0.1-2.5-0.2-3.8-0.1
		c-1.3,0-2.6,0.2-3.9,0.4c-3.7,0.3-7.5,0.6-11.2,1c-2.5,0.2-4.9,0.1-7.4,0.2c-4.5,0.2-9-0.4-13.5-0.2c-2.8,0.2-5.5-0.4-8.2-0.2
		c-3.9,0.3-7.9,0.9-11.8,0.9c-3,0-5.9,0.1-8.9,0.4c-1.5,0.1-2.6-0.1-4-0.1c-2.6-0.1-5.3,0.5-7.8,0.7c-4.9,0.4-9.7,0.8-14.6,1.2
		c-4,0.3-8,0.7-12,1c-2.1,0.2-4.3,0.5-6.4,0.6c-1.5,0.1-2.9-0.2-4.4-0.1c-2.9,0.3-5.7,0.5-8.6,0.7c-6.6,0.5-12.8,2.1-19.2,3.3
		c-1.4,0.3-2.7,0.7-4,0.9c-2.2,0.4-5.5-0.1-7.5-0.2c-4.6-0.3-9.2-0.7-13.9-1c-7.1-0.5-14.3-0.8-21.5-0.8c-2.9,0-5.7-0.2-8.7,0.3
		c-2.1,0.3-4.2,0.9-6.3,1.4c-4.6,0.9-9.3,1.4-14,2c-5.5,0.7-11.1,1-16.7,1.5c-3.9,0.3-7.8,0.7-11.7,1c-4.9,0.4-9.9,0.8-14.8,1.3
		L129.6,967.4z'
      />
    </g>

    <g>
      <path
        d='M968.2,890.4c-7.8-31.6-25.2-55.3-48.4-75.6c-18-15.8-41-22.3-64-26.2c-36.9-6.2-72.9,0.7-104.9,19.1
	c-41.2,23.7-65.2,61-73.6,107.9c-1.6,8.9-3.5,18.1-2.8,27c2.2,31.1,10.3,60.7,27.4,87.2c26,40.3,61.4,64.6,113,63.1
	c0.1,0,1.9,0.4,3.6,0.2c16-1.7,33.1-0.7,47.9-6c32-11.3,59.7-29.8,77.9-59.8c9.6-15.8,21.2-31.4,22.7-50
	C969.5,948.5,975.6,920.1,968.2,890.4z M944.4,974.8c-3.3,15.5-15.5,34.8-25.8,47.2c-15.4,18.5-31.3,26.7-55.5,42.9
	c-16.1,10.7-30.3,10.3-44,10.7c-2.6,0.1-5.1,0-5.2,0c-37.7-3.7-72.7-18.6-94.9-54.1c-14.6-23.3-13.5-44.7-15.4-72.2
	c-0.5-7.8-1-18.5,0.3-26.4c7.1-41.3,14.2-74.3,59-95.9c28.6-13.8,56.1-23.5,87.6-18.1c19.6,3.4,39.3,9.2,54.6,23.1
	c19.9,18,38.6,29.2,43,63.7C952.7,926,949.7,949.9,944.4,974.8z'
      />

      <path
        d='M942.8,872.6c-0.8,0.2-1.7,0.5-2.5,0.7c-1.1,0.3-2.2,0.2-3.3,0.4c-1,0.2-1.9,0.1-2.9,0.4c-0.9,0.2-1.7,0.5-2.6,0.7
		c-2,0.5-4.4,0.9-6.3,1.8c-1,0.5-1.9,1.1-2.9,1.6c-1.6,0.9-3.1,1.3-4.9,1.8c-4.2,1.1-9.1,1.9-12.9,3.9c-4.6,2.4-10.2,3.3-15.1,4.7
		c-7.8,2.1-15.7,5.2-23.1,8.6c-4.8,2.2-9.8,3.9-14.7,5.8c-2.9,1.1-5.8,2.1-8.9,2.9c-3.5,0.9-7.1,1.8-10.6,3
		c-6.1,2.2-12.3,3.9-18.3,6.2c-8.2,3.1-17.1,4.4-25.4,7.1c-6.7,2.2-13.2,4.5-20,6.3c-6.7,1.9-13.5,3.7-20.2,5.5
		c-4.1,1.1-8.5,2-12.4,3.7c-2.1,1-4.5,1.3-6.7,1.9c-2.5,0.7-4.9,1.4-7.4,2c-2.9,0.8-5.9,1.5-8.8,2.4c-1.7,0.5-3.1,1.7-4.8,2.2
		c-2,0.6-3.7,1.5-5.7,2c-1.2,0.3-2.5,0.9-3.7,0.9c-0.9,0-1.7,0.1-2.7,0.4c-2.1,0.6-4.2,1.2-6.3,1.7l8,24.4c3.3-0.9,6.5-1.8,9.8-2.7
		c1.3-0.3,2.3-1,3.5-1.4c1.2-0.4,2.6-0.7,3.8-1c3.6-1,7.2-2,10.8-3c2.5-0.7,4.6-1.6,7.1-2.4c4.3-1.3,8.3-3.5,12.6-4.8
		c2.7-0.8,5-2.3,7.7-3c3.8-1,7.7-1.9,11.4-3.2c2.8-1,5.6-1.9,8.5-2.7c1.4-0.4,2.5-1,3.7-1.5c2.4-1,5.1-1.4,7.6-2.1
		c4.7-1.3,9.4-2.6,14.2-3.9c3.9-1.1,7.7-2.1,11.6-3.2c2.1-0.6,4.2-1,6.2-1.7c1.4-0.5,2.7-1.2,4.1-1.6c2.8-0.7,5.6-1.5,8.3-2.3
		c6.3-1.8,12.7-2.5,19.2-3.6c1.4-0.2,2.8-0.3,4.1-0.5c2.2-0.4,5.1-2,6.9-2.8c4.2-1.9,8.4-3.8,12.7-5.7c6.5-2.9,13.1-5.7,19.9-8.2
		c2.8-1,5.3-2.1,8.2-2.7c2.1-0.4,4.3-0.6,6.4-0.9c4.6-0.7,9.2-1.9,13.8-3c5.4-1.3,10.8-2.9,16.2-4.3c3.8-1,7.5-2.1,11.3-3.1
		c4.8-1.3,9.6-2.6,14.3-3.9L942.8,872.6z'
      />
      <path
        d='M950.5,977.9c-0.9-0.1-1.7-0.2-2.6-0.3c-1.2-0.1-2.1-0.6-3.3-0.8c-1-0.2-1.9-0.6-2.9-0.8c-0.9-0.2-1.8-0.2-2.7-0.3
		c-2-0.2-4.4-0.9-6.5-0.7c-1.1,0.1-2.2,0.3-3.3,0.4c-1.8,0.3-3.4,0-5.2-0.2c-4.3-0.5-9.2-1.7-13.5-1.3c-5.2,0.5-10.7-0.7-15.8-1.4
		c-8-1-16.5-1.1-24.6-0.7c-5.3,0.2-10.5-0.1-15.8-0.2c-3.1-0.1-6.2-0.2-9.3-0.6c-3.6-0.5-7.3-1.1-10.9-1.2c-6.4-0.3-12.8-1-19.3-1.2
		c-8.8-0.3-17.5-2.4-26.2-3c-7-0.5-13.9-0.8-20.9-1.7c-6.9-0.8-13.9-1.7-20.8-2.5c-4.2-0.5-8.6-1.4-12.9-1.2
		c-2.3,0.1-4.7-0.5-6.9-0.7c-2.5-0.3-5.1-0.6-7.6-0.9c-3-0.4-6.1-0.8-9.1-1.1c-1.8-0.2-3.5,0.4-5.3,0.3c-2-0.2-4-0.1-6.1-0.3
		c-1.2-0.1-2.6-0.1-3.8-0.6c-0.9-0.3-1.6-0.5-2.6-0.7c-2.2-0.2-4.3-0.5-6.5-0.8l-1.8,25.6c3.3,0.4,6.7,0.8,10,1.2
		c1.3,0.2,2.5-0.1,3.8,0c1.3,0.1,2.6,0.3,3.9,0.5c3.7,0.4,7.4,0.9,11.2,1.3c2.5,0.3,4.9,0.3,7.4,0.5c4.5,0.4,9-0.1,13.5,0.3
		c2.8,0.3,5.5-0.2,8.2,0.1c3.9,0.5,7.8,1.1,11.8,1.3c3,0.1,5.9,0.4,8.9,0.7c1.4,0.2,2.6,0,4,0c2.6,0,5.2,0.7,7.8,1
		c4.9,0.6,9.7,1.2,14.6,1.8c4,0.5,8,1,11.9,1.4c2.1,0.3,4.2,0.6,6.4,0.8c1.5,0.1,2.9-0.1,4.4,0.1c2.9,0.4,5.7,0.7,8.6,1
		c6.5,0.8,12.7,2.5,19.1,3.9c1.4,0.3,2.7,0.8,4,1.1c2.2,0.5,5.5,0.1,7.5,0.1c4.6-0.2,9.3-0.4,13.9-0.5c7.2-0.2,14.3-0.3,21.5-0.1
		c2.9,0.1,5.7,0,8.6,0.6c2.1,0.4,4.2,1.1,6.3,1.6c4.6,1.1,9.3,1.7,13.9,2.4c5.5,0.9,11.1,1.4,16.6,2.1c3.9,0.5,7.7,0.9,11.6,1.4
		c4.9,0.6,8.9,0.7,13.8,1.3L950.5,977.9z'
      />
    </g>
  </svg>
)
