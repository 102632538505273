import React from 'react'

export default ({ ...props }) => (
  <svg
    width='35'
    height='134'
    viewBox='0 0 35 134'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M33.5 10.0381L33.5 0.973144L9 0.973143L9 5.31314L18.45 5.31314L18.45 9.58314C18.45 14.7281 22.23 17.5981 26.22 17.5981C29.93 17.5981 33.5 15.1831 33.5 10.0381ZM22.405 5.31314L29.51 5.31314L29.51 9.65314C29.51 12.0331 27.97 13.2581 26.115 13.2581C24.12 13.2581 22.405 11.9631 22.405 9.40814L22.405 5.31314Z'
      fill='#FFF4CC'
    />
    <path
      d='M26.78 21.3019L9 21.3019L9 25.5369L18.31 25.5369C21.495 25.5369 23.105 27.3569 23.105 29.8419C23.105 30.5069 22.965 31.2769 22.685 32.0119L26.64 32.6769C26.885 31.8369 27.025 30.9969 27.025 30.2269C27.025 28.0919 26.08 26.2369 23.98 25.4319L26.78 25.4319L26.78 21.3019Z'
      fill='#FFF4CC'
    />
    <path
      d='M27.2 43.1613C27.2 37.8763 23.28 34.0263 17.995 34.0263C12.71 34.0263 8.79 37.8763 8.79 43.1613C8.79 48.4463 12.71 52.2963 17.995 52.2963C23.28 52.2963 27.2 48.4463 27.2 43.1613ZM17.995 38.2613C21.18 38.2613 23.42 40.2213 23.42 43.1613C23.42 46.1363 21.18 48.0963 17.995 48.0963C14.81 48.0963 12.605 46.1363 12.605 43.1613C12.605 40.2213 14.81 38.2613 17.995 38.2613Z'
      fill='#FFF4CC'
    />
    <path
      d='M34.935 58.5492C34.935 56.9042 33.745 55.6792 32.17 55.6792C30.63 55.6792 29.44 56.9042 29.44 58.5492C29.44 60.2292 30.63 61.4192 32.17 61.4192C33.745 61.4192 34.935 60.2292 34.935 58.5492ZM1.755 51.0942C1.125 52.4592 0.774992 53.7192 0.774992 55.0142C0.774991 58.2692 2.97999 60.6842 6.93499 60.6842L26.78 60.6842L26.78 56.4842L7.00499 56.4842C5.46499 56.4842 4.58999 55.5392 4.58999 54.2792C4.58999 53.7192 4.73 53.0542 5.15 52.3542L1.755 51.0942Z'
      fill='#FFF4CC'
    />
    <path
      d='M16.56 81.693L18.38 81.693C24.155 81.693 27.2 78.648 27.2 73.818C27.2 68.183 23.035 64.788 17.785 64.788C12.465 64.788 8.78999 68.288 8.78999 73.748C8.78999 76.898 10.015 79.313 12.22 81.203L14.775 78.963C13.41 77.878 12.465 76.303 12.465 74.098C12.465 71.333 13.83 69.548 16.56 69.058L16.56 81.693ZM19.675 69.023C22.195 69.443 23.665 71.018 23.665 73.608C23.665 76.233 22.16 77.458 19.675 77.493L19.675 69.023Z'
      fill='#FFF4CC'
    />
    <path
      d='M13.27 101.069L15.335 98.2338C13.62 97.2188 12.605 95.4688 12.605 93.6138C12.605 90.4638 15.055 88.5388 18.065 88.5388C21.075 88.5388 23.42 90.2538 23.42 93.4038C23.42 95.1888 22.65 96.5538 21.145 97.5688L23.63 100.579C25.975 98.8988 27.2 96.4488 27.2 93.4038C27.2 87.8738 23.035 84.3388 17.89 84.3388C12.885 84.3388 8.78999 87.5238 8.78999 93.2288C8.78999 96.5888 10.435 99.2838 13.27 101.069Z'
      fill='#FFF4CC'
    />
    <path
      d='M26.78 101.985L23 101.985L23 105.59L14.81 105.59C10.96 105.59 8.78999 108.18 8.78999 111.61C8.78999 113.29 9.31499 115.005 10.365 116.615L13.445 115.495C12.885 114.585 12.605 113.57 12.605 112.73C12.605 111.12 13.515 109.825 15.51 109.825L23 109.825L23 115.11L26.78 115.11L26.78 109.825L31.33 109.825L31.33 105.59L26.78 105.59L26.78 101.985Z'
      fill='#FFF4CC'
    />
    <path
      d='M21.915 130.418L24.68 132.623C26.36 130.943 27.2 128.458 27.2 126.043C27.2 122.508 25.38 119.358 21.6 119.358C17.435 119.358 16.7 123.243 16.14 126.113C15.72 128.283 15.335 129.403 14.145 129.403C13.025 129.403 12.465 128.283 12.465 126.743C12.465 124.783 13.27 122.228 15.09 120.723L12.15 118.378C9.97999 120.373 8.78999 123.383 8.78999 126.358C8.78999 130.453 10.995 133.603 14.635 133.603C18.485 133.603 19.185 130.033 19.745 127.198C20.2 124.783 20.515 123.523 21.81 123.523C22.965 123.523 23.63 124.538 23.63 126.078C23.63 127.443 23.105 129.228 21.915 130.418Z'
      fill='#FFF4CC'
    />
  </svg>
)
