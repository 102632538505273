import React from 'react'

const Smidjur = ({ ...props }) => {
  return (
    <svg
      width='36'
      height='125'
      viewBox='0 0 36 125'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M26.705 14.19L29.68 17.025C32.095 15.065 33.285 12.09 33.285 9.22C33.285 4.67 30.485 0.855003 25.55 0.855002C21.21 0.855002 19.705 4.005 18.375 8.59C17.64 11.04 16.905 13.35 14.84 13.35C13.055 13.35 11.865 11.775 11.865 9.465C11.865 7.295 12.88 4.845 15.015 3.34L11.9 0.330002C8.96 2.64 7.665 6.105 7.665 9.255C7.665 13.805 10.255 17.76 15.33 17.76C19.74 17.76 21.105 14.575 22.365 10.13C23.065 7.645 23.8 5.23 26.145 5.23C28.07 5.23 29.12 6.875 29.12 9.045C29.12 10.935 28.28 13 26.705 14.19Z'
        fill='#FFF4CC'
      />
      <path
        d='M26.005 21.4777L8.225 21.4777V25.7127H17.955C21.245 25.7127 22.68 27.3227 22.68 29.3877C22.68 31.5577 21.245 32.4677 19.005 32.4677H8.225V36.6677H17.955C21.245 36.6677 22.68 38.3127 22.68 40.3777C22.68 42.5477 21.245 43.4577 19.005 43.4577H8.225V47.6577L19.74 47.6577C24.045 47.6577 26.425 45.5227 26.425 41.7427C26.425 39.0827 25.165 37.1227 22.96 36.0727C25.235 35.2327 26.425 33.3427 26.425 30.7527C26.425 28.2677 25.27 26.5877 23.345 25.6077H26.005V21.4777Z'
        fill='#FFF4CC'
      />
      <path
        d='M34.16 54.8253C34.16 53.1453 32.97 51.9553 31.395 51.9553C29.855 51.9553 28.665 53.1453 28.665 54.8253C28.665 56.5053 29.855 57.6603 31.395 57.6603C32.97 57.6603 34.16 56.5053 34.16 54.8253ZM8.225 52.6903V56.9253L26.005 56.9253V52.6903H8.225Z'
        fill='#FFF4CC'
      />
      <path
        d='M35.35 70.7656L32.41 69.5756C32.865 68.0706 33.075 66.4256 33.075 64.5706L29.33 64.3956C29.33 65.7606 29.155 67.0206 28.875 68.1406L26.25 67.0906L25.585 68.9456L28.21 70.0306C26.81 73.1106 24.115 75.0356 20.16 75.2106C22.715 74.0556 24.045 71.1506 24.045 68.4206C24.045 64.2906 21.07 60.9306 16.45 60.9306C11.2 60.9306 8.015 64.8156 8.015 69.6106C8.015 75.1406 12.285 79.1656 19.18 79.1656C24.745 79.1656 29.505 76.5756 31.745 71.5006L34.72 72.7606L35.35 70.7656ZM16.275 65.3056C19.18 65.3056 20.545 67.4406 20.545 69.6456C20.545 71.8856 19.075 74.0906 16.31 74.0906C13.79 74.0906 11.97 72.3056 11.97 69.6456C11.97 66.9506 13.79 65.3056 16.275 65.3056Z'
        fill='#FFF4CC'
      />
      <path
        d='M34.16 85.7954C34.16 84.1504 32.97 82.9254 31.395 82.9254C29.855 82.9254 28.665 84.1504 28.665 85.7954C28.665 87.4754 29.855 88.6654 31.395 88.6654C32.97 88.6654 34.16 87.4754 34.16 85.7954ZM0.98 78.3404C0.35 79.7054 5.66063e-08 80.9654 0 82.2604C-1.42281e-07 85.5154 2.205 87.9304 6.16 87.9304H26.005V83.7304H6.23C4.69 83.7304 3.815 82.7854 3.815 81.5254C3.815 80.9654 3.955 80.3003 4.375 79.6003L0.98 78.3404Z'
        fill='#FFF4CC'
      />
      <path
        d='M26.005 108.764V104.529H16.695C13.65 104.529 11.795 102.744 11.795 100.259C11.795 98.2291 13.02 96.9691 15.575 96.9691H26.005V92.7341H14.7C10.115 92.7341 8.015 95.5341 8.015 99.1041C8.015 101.939 9.345 103.549 11.13 104.634H8.225V108.764H26.005Z'
        fill='#FFF4CC'
      />
      <path
        d='M26.005 113.524H8.225L8.225 117.759H17.535C20.72 117.759 22.33 119.579 22.33 122.064C22.33 122.729 22.19 123.499 21.91 124.234L25.865 124.899C26.11 124.059 26.25 123.219 26.25 122.449C26.25 120.314 25.305 118.459 23.205 117.654H26.005V113.524Z'
        fill='#FFF4CC'
      />
    </svg>
  )
}

export default Smidjur
